export default function Pagination({pageSize, page, setPage, totalCount, pageInfo}) {
  return (
    <nav
    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
    aria-label="Pagination"
  >
    <div className="hidden sm:block">
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{page*pageSize+1}</span> to <span className="font-medium">{page*pageSize+pageSize}</span> of{' '}
        <span className="font-medium">{totalCount}</span> results
      </p>
    </div>
    <div className="flex-1 flex justify-between sm:justify-end">
      <button
        onClick={()=>setPage(page-1)}
        disabled={!pageInfo?.hasPreviousPage}
        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
      >
        Previous
      </button>
      <button
        onClick={()=>setPage(page+1)}
        disabled={!pageInfo?.hasNextPage}
        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
      >
        Next
      </button>
    </div>
  </nav>
  )
}
