/* eslint-disable react/prop-types */

export default function ZebraDetailList({ inline, rows }) {
  const outerClassNames = inline ? "border-t" : "border rounded-md";

  const classNames = [
    "bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
    "bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6",
  ];

  return (
    <div className={`border-gray-200 overflow-hidden ${outerClassNames}`}>
      <dl>
        {rows.map(([title, description, type], index) => (
          <div className={classNames[index % 2]} key={index}>
            <dt className="text-sm font-medium text-gray-500">{title}</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {type === "link" ? (
                <a
                  href={description}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:text-blue-500"
                >
                  {description}
                </a>
              ) : (
                description
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
