import type { ItemSchema } from "@app/types";
import addOnProduct from "./addonproduct";
import associatedClub from "./associated_club";
import bicycle from "./bicycle";
import brand from "./brand";
import brandFamily from "./brand_family";
import discountCode from "./discount_code";
import model from "./model";
import order from "./order";
import org from "./org";
import product from "./product";
import user from "./user";
export const cms: ItemSchema[] = [
  user,
  org,
  brand,
  brandFamily,
  model,
  bicycle,

  product,
  addOnProduct,
  discountCode,
  associatedClub,
];

export const forms: ItemSchema[] = [order];

export default cms;
