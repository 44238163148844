import {
  useOrgQuery,
  useOrgsQuery,
  useUpdateOrgMutation,
} from "@app/graphql/pim";
import { FieldType, Form, ItemSchema, ListColumn } from "@app/types";
import { z } from "zod";
import org_permission from "./org_permission";

export const addInitialValues = {
  name: null,
  fikenId: null,
  tradein: false,
};

export const addForm: Form = [
  {
    id: "name",
    name: "Partnernavn",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "fikenId",
    name: "Fiken Levrandørnummersen",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "tradein",
    name: "Tilbyr innbytte",
    type: FieldType.boolean,
    colspan: 4,
    required: false,
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "id",
    name: "Auth0 id",
  },

  {
    id: "name",
    name: "Partnernavn",
  },

  {
    id: "fikenId",
    name: "Fiken Levrandørnummer",
  },
];

const org: ItemSchema = {
  id: "org",
  name: "Partner",
  plural: "Partnere",
  listColumns,
  addForm: null,
  editForm,
  addInitialValues,
  editInitialValues,
  useItemsQuery: useOrgsQuery,
  useItemQuery: useOrgQuery,
  useCreateItemMutation: null,
  useUpdateItemMutation: useUpdateOrgMutation,
  parseItem: (data) => data?.org || {},
  parseItems: (data) =>
    ({
      totalCount: data?.orgs?.totalCount,
      items: data?.orgs?.nodes,
    } || []),
  createInputFromValues: (values) => ({
    org: values,
  }),
  children: [org_permission],
  parentId: "orgId",
};

export default org;
