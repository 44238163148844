"use client";
import {
  useConnectOrderFikenSaleMutation,
  useUpdateOrderMutation,
  useUpdateInventoryMutation,
} from "@app/graphql/pim";
import {
  Order as OrderType,
  OrderStatus,
  InventoryStatus,
} from "@app/graphql/types";
import {
  FieldType,
  FormFieldComponentProps,
  Form as FormType,
} from "@app/types";

import { useState } from "react";

import Form from "../../../components/form/Form";
// import {FormWrapper} from "../../../components/form/FormWrapper";
//

function FikenSaleLink({ id, name, data, refetch }: FormFieldComponentProps) {
  if (!data.fikenSaleId) {
    return null;
  }
  return (
    <a
      className="text-sportgreen"
      target="_blank"
      rel="noreferrer"
      href={`https://fiken.no/foretak/${process.env.REACT_APP_FIKEN_COMPANY_SLUG}/handel/salg/${data.fikenSaleId}`}
    >
      Fiken salg
    </a>
  );
}

type FormDataType = {
  invoiceNumber: string | null;
  paidAmount: number | null;
  shippingCost: number | null;
};

export default function ShippingForm({
  id,
  name,
  data,
  refetch,
}: FormFieldComponentProps) {
  const order = data as OrderType;
  const [loading, setLoading] = useState(false);

  const [connectOrderFikenSale] = useConnectOrderFikenSaleMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const [updateInventory] = useUpdateInventoryMutation();
  const createInvoiceformData: FormDataType = {
    invoiceNumber: null,
    paidAmount: null,
    shippingCost: null,
  };
  const formData: FormDataType = {
    invoiceNumber: null,
    paidAmount: null,
    shippingCost: null,
  };
  const dictionary = {};

  const formatCurency = (value: number | null | undefined) => {
    if (value === null || value === undefined) {
      return "";
    }
    value = value / 100;
    return value.toLocaleString("nb-NO", {
      style: "currency",
      currency: "NOK",
    });
  };

  const createInvoiceSave = async (data: any) => {
    if (loading) {
      return;
    }
    if (data.paidAmount == null || data.shippingCost == null) {
      alert("Please fill in all required fields");
    }
    setLoading(true);
    const paidAmount = parseInt(data.paidAmount, 10);
    const shippingCost = parseInt(data.shippingCost, 10) * 100;
    const totalDue = paidAmount * 100;
    const discount = order.total - totalDue;
    console.log(
      "paid amount",
      paidAmount,
      shippingCost,
      totalDue,
      discount,
      totalDue + shippingCost
    );

    const inventoryOrderItem = order.orderItems?.nodes.filter(
      (item) => item.inventory
    )[0];
    const inventoryId = inventoryOrderItem?.inventory?.id;
    try {
      await updateOrder({
        variables: {
          id: order.id,
          patch: {
            totalDue: totalDue + shippingCost,
            shippingCost: shippingCost,
            discountAmount: discount,
            status: OrderStatus.Paid,
          },
        },
      });
      if (inventoryId) {
        await updateInventory({
          variables: {
            id: inventoryId,
            patch: {
              status: InventoryStatus.Sold,
            },
          },
        });
      } else {
        alert("No inventory found");
        console.error("No inventory found", inventoryOrderItem);
      }
      refetch?.();
    } catch (e) {
      alert("Error updating order");
      console.error("Error updating orde", e);
    } finally {
      setLoading(false);
    }
  };
  const save = (data: any) => {
    if (loading) {
      return;
    }
    if (
      !order ||
      !data.invoiceNumber ||
      data.paidAmount == null ||
      data.shippingCost == null
    ) {
      alert("Please fill in all required fields");
    }

    setLoading(true);
    connectOrderFikenSale({
      variables: {
        orderId: order.id,
        invoiceNumber: data.invoiceNumber,
        paidAmount: parseInt(data.paidAmount, 10),
        shippingCost: parseInt(data.shippingCost, 10),
      },
    })
      .then(() => {
        setLoading(false);
        refetch?.();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const connectForm: FormType = [
    {
      id: "invoiceNumber",
      name: "Fiken Fakturanummer",
      type: FieldType.number,
      colspan: 1,
      required: true,
    },
    {
      id: "paidAmount",
      name: "Fakurert beløp uten frakt (hele kroner)",
      type: FieldType.number,
      colspan: 2,
      required: true,
    },
    {
      id: "shippingCost",
      name: "Frakt (hele kroner)",
      type: FieldType.number,
      colspan: 1,
      required: true,
    },
  ];

  const createInvoiceForm: FormType = [
    {
      id: "paidAmount",
      name: "Betalt uten frakt (hele kroner)",
      type: FieldType.number,
      colspan: 2,
      required: true,
    },
    {
      id: "shippingCost",
      name: "Fraktkostnad kunde (hele kroner)",
      type: FieldType.number,
      colspan: 2,
      required: true,
    },
  ];

  const shipingShippedForm: FormType = [
    {
      id: "paymentMethod",
      name: "Betalingsmetode",
      type: FieldType.label,
      colspan: 1,
      required: true,
    },
    {
      id: "fikenSaleId",
      name: "Fiken SALE ID",
      type: FieldType.component,
      colspan: 1,
      fieldProps: {
        component: FikenSaleLink,
      },
      required: true,
    },
    {
      id: "total",
      name: "Totalt beløp",
      type: FieldType.label,
      colspan: 1,
      required: true,
      parseFormData: (data: any) => formatCurency(data),
    },
    {
      id: "totalDue",
      name: "Betalt beløp",
      type: FieldType.label,
      colspan: 1,
      required: true,
      parseFormData: (data: any) => formatCurency(data),
    },

    {
      id: "discountAmount",
      name: "Discount",
      type: FieldType.label,
      colspan: 1,
      required: true,
      parseFormData: (data: any) => formatCurency(data),
    },
  ];
  const orderHasAddressData =
    order.shippingFirstName &&
    order.shippingLastName &&
    order.shippingLastName &&
    order.shippingAddressLine &&
    order.shippingPostalCode &&
    order.shippingPostalPlace &&
    order.shippingCountry &&
    order.email &&
    order.phoneNumber;

  return (
    <div className="border bg-sportlightgrey rounded p-4 my-4">
      <h3 className="text-lg font-medium leading-6 mb-4 text-gray-900">
        {name}
      </h3>
      <>
        <Form
          form={shipingShippedForm}
          data={formData}
          extraData={order}
          dictionary={dictionary}
          loading={loading}
          itemId={"order"}
        />
        {order.status === "CREATED" && order.paymentMethod === "INVOICE" && (
          <>
            {!orderHasAddressData ? (
              <div className="text-red-800 text-sm">
                Vennligst fyll ut og lagre personalia og adresse for å kunne
                koble til eller lage fiken faktura.{" "}
              </div>
            ) : (
              <div className="mt-4">
                <hr className="my-4" />
                <h4 className="text-sm font-semibold leading-6 mb-2 text-gray-900">
                  Koble til Fiken faktura
                </h4>
                <Form
                  form={connectForm}
                  data={formData}
                  extraData={order}
                  save={save}
                  dictionary={dictionary}
                  loading={loading}
                  itemId={"order"}
                  actionName={"Koble til Fiken fakura"}
                />
                <hr className="my-4" />
                <h4 className="text-sm font-semibold leading-6 mb-2 text-gray-900">
                  Lag Fiken faktura (faktura blir laget når ordren er sendt)
                </h4>
                <Form
                  form={createInvoiceForm}
                  data={createInvoiceformData}
                  extraData={order}
                  save={createInvoiceSave}
                  dictionary={dictionary}
                  loading={loading}
                  itemId={"order"}
                  actionName={"Lag fiken fakura"}
                />
                <hr className="my-4" />
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}
