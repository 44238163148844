import Form from "../components/calculator/Form";
import PriceQueryList from "./PriceQueries/PriceQueryList";

export default function TradeInDashboard() {
  return (
    <>
      <div className="space-y-4 rounded-lg bg-white px-4 py-6 m-4  shadow-sportbox">
        <Form type={"tradein"} />
      </div>
      <PriceQueryList type={"tradein"} />
    </>
  );
}
