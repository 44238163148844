import { Link } from "react-router-dom";

const NotFound = () => (
  <>
    <div>
      <h1 className="pt-10 text-6xl text-center">404 - Not Found!</h1>
      <Link
        className="block text-sportgreen underline font-bold py-10 text-xl text-center"
        to="/"
      >
        Go Home
      </Link>
    </div>
  </>
);

export default NotFound;
