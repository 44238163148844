import { gql } from "@apollo/client";

export const BICYCLEQUERY = gql`
  query BicyclePriceQuery($id: UUID!) {
    bicyclePriceQuery(id: $id) {
      id
      step
      bicycle {
        id
        name
      }
      purchaseProtocol {
        sequence
      }
      bicycleIntakeForms(first: 1) {
        nodes {
          sku
          inventories(first: 1) {
            nodes {
              id
              status
              order {
                id
              }
            }
          }
          id
          status
        }
      }

      type
      submittedByUserId
      userId
      status
      sportiendaScore
      retailPriceNok
      ownerPostalPlace
      ownerPostalCode
      ownerPhone
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerAddress
      orgId
      offeredPrice
      idVerificationNuber
      frameNumber
      electric
      conditionWheels
      conditionTires
      conditionHeadset
      conditionGeneral
      conditionCrank
      conditionCosmeticFrame
      conditionChain
      conditionCasette
      conditionBottomBracket
      conditionDampersFront
      conditionDampersBack
      conditionBattery
      conditionMotor
      comment
      brandBonus
      bicycleTypeBonus
      bicycleType
      bicycleManualModel
      calculatedSportiendaScore
      currentPrice
      estimatedArrival
      shippingReference
      ourShippingCost
      intakeStatus
      receiptUrl
      contractUrl
      driveType
      conditionBrakes
      conditionWheelsFront
      conditionWheelsBack
      conditionTiresFront
      conditionTiresBack
      brandMotor
      breakType
      km
      fikenPurchaseId
    }
  }
`;

export const SUBMIT = gql`
  mutation SubmitBicyclePriceQuery($bicyclePriceQueryId: UUID!) {
    submitBicyclePriceQuery(
      input: { bicyclePriceQueryId: $bicyclePriceQueryId }
    ) {
      bicyclePriceQuery {
        status
        id
        calculatedSportiendaScore
        currentPrice
        offeredPrice
        retailPriceNok
      }
    }
  }
`;

export const REQUESTSIGNATURE = gql`
  mutation RequestSignaturePriceQuery($bicyclePriceQueryId: UUID!) {
    requestSignaturePriceQuery(
      input: { bicyclePriceQueryId: $bicyclePriceQueryId }
    ) {
      bicyclePriceQuery {
        status
        id
        calculatedSportiendaScore
        currentPrice
        offeredPrice
        retailPriceNok
      }
    }
  }
`;

export const HANDED_IN = gql`
  mutation HandInBicyclePriceQuery($bicyclePriceQueryId: UUID!) {
    handInBicyclePriceQuery(
      input: { bicyclePriceQueryId: $bicyclePriceQueryId }
    ) {
      bicyclePriceQuery {
        status
        id
        calculatedSportiendaScore
        currentPrice
        offeredPrice
        retailPriceNok
      }
    }
  }
`;

export const UPDATE = gql`
  mutation Update($patch: BicyclePriceQueryPatch!, $id: UUID!) {
    updateBicyclePriceQuery(input: { patch: $patch, id: $id }) {
      clientMutationId
      bicyclePriceQuery {
        id
        step
        type
        submittedByUserId
        userId
        status
        sportiendaScore
        ownerPostalPlace
        ownerPostalCode
        ownerPhone
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerAddress
        orgId
        offeredPrice
        idVerificationNuber
        frameNumber
        electric
        conditionWheels
        conditionTires
        conditionHeadset
        conditionGeneral
        conditionCrank
        conditionCosmeticFrame
        conditionChain
        conditionCasette
        conditionBottomBracket
        conditionDampersFront
        conditionDampersBack
        conditionBattery
        conditionMotor
        breakType
        comment
        brandBonus
        bicycleTypeBonus
        bicycleType
        bicycleManualModel
      }
    }
  }
`;

export const ACCEPT_CURRENT_OFFER = gql`
  mutation Update($id: UUID!) {
    acceptPriceQuery(input: { bicyclePriceQueryId: $id }) {
      clientMutationId
      bicyclePriceQuery {
        id
        status
        step
        ownerPostalPlace
        ownerPostalCode
        ownerPhone
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerAddress
        frameNumber
        bicycleType
        electric
        conditionWheels
        conditionTires
        conditionHeadset
        conditionGeneral
        conditionCrank
        conditionCosmeticFrame
        conditionChain
        conditionCasette
        conditionBottomBracket
        conditionDampersFront
        conditionDampersBack
        conditionBattery
        conditionMotor
        offeredPrice
        retailPriceNok
        comment
      }
    }
  }
`;
