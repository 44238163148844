import { useState } from "react";

import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { TextField } from "../../components/atom/inputs";

import { gql, useMutation } from "@apollo/client";

const CREATE_INTAKE = gql`
  mutation CreateIntakeForm($bicyclePriceQueryId: UUID!) {
    createBicycleIntakeForm(input: { bicyclePriceId: $bicyclePriceQueryId }) {
      bicycleIntakeForm {
        id
      }
    }
  }
`;

export default function NewIntake() {
  const navigate = useNavigate();
  const [bicycleId, setBicycleId] = useState();

  const [createIntake, { data, loading }] = useMutation(CREATE_INTAKE, {
    variables: { bicycleId: bicycleId },
  });

  const create = (ev) => {
    ev.preventDefault();

    const variables = {
      bicyclePriceQueryId: bicycleId,
    };

    const res = createIntake({ variables });
    res
      .then(({ data }) => {
        if (data?.createBicycleIntakeForm?.bicycleIntakeForm?.id) {
          navigate(
            `/intake/${data?.createBicycleIntakeForm?.bicycleIntakeForm?.id}`
          );
        }
      })
      .catch(function (err) {
        alert(
          (err && err.message) ||
            "An error ocurred while processing the request"
        );
      });
  };

  return (
    <div className="px-4 pt-4">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            New Intake
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Please enter bicycle price query id</p>
          </div>
          <form onSubmit={create}>
            <div className="mt-5 space-y-2">
              <div>
                <TextField
                  required={true}
                  disabled={loading}
                  value={bicycleId || ""}
                  onChange={(e) => setBicycleId(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-5 space-y-2">
              <button
                type="submit"
                disabled={loading}
                className="inline-flex items-center justify-center disabled:bg-sportgrey disabled:text-black px-4 py-2 border border-transparent font-medium rounded-md text-white bg-sportgreen shadow-sportgreen/50 hover:shadow-lg focus:rshadow-lg  sm:text-sm"
              >
                Fortsett{" "}
                <ArrowRightCircleIcon
                  className="ml-2 h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
