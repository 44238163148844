import { gql, useMutation, useQuery } from "@apollo/client";
import { useCheckClubCodeLazyQuery } from "@app/graphql/pim";
import { CheckCircleIcon, StarIcon } from "@heroicons/react/24/solid";
import norskValidator from "norsk-validator";
import {
  createContext,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Loading from "../../components/atom/Loading";
import {
  Button,
  Field,
  FormButton,
  FormFields,
  FormSummary,
  initialFormState,
} from "../../components/common/Form";
import { ImageUpload } from "../../components/common/ImageUpload";
import Steps, { CompactSteps } from "../../components/common/Steps";
import { trackEvent } from "../../utils/stats";

import { PopupButton } from "../../components/atom/Popup";

// FIXME: Move file

import {
  conditionsFromData,
  priceQuerySteps as steps,
  typesFromData,
} from "@app/config";

import { classNames } from "../../utils/dom";
const NotFound = lazy(() => import("../../components/layout/notfound"));

/* eslint-disable react/prop-types */

const LOAD_INITIAL_OFFER = gql`
  query BicylcePirceQueryQuery($id: UUID!) {
    bicyclePriceQuery(id: $id) {
      step
      status
      type
      ownerPostalPlace
      ownerPostalCode
      ownerPhone
      ownerFirstName
      ownerLastName
      ownerEmail
      ownerAddress
      payoutBankAccount
      frameNumber
      clubCode
      electric
      conditionWheels
      conditionTires
      conditionHeadset
      conditionGeneral
      conditionCrank
      conditionCosmeticFrame
      conditionChain
      conditionCasette
      conditionBottomBracket
      conditionDampersFront
      conditionDampersBack
      conditionBattery
      conditionMotor
      comment
      bicycleType
      bicycleManualModel
      bicycleId
      offeredPrice
      retailPriceNok
      receiptUrl
      contractUrl
      driveType
      conditionBrakes
      conditionTiresFront
      conditionTiresBack
      conditionWheelsFront
      conditionWheelsBack
      brandMotor
      breakType
      km

      bicycle {
        id
        name
        year
        spokesUrl
        model {
          id
          name
          brandFamily {
            id
            name
            brand {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const UPDATE_CURRENT_OFFER = gql`
  mutation Update(
    $patch: BicyclePriceQueryInput!
    $id: UUID!
    $submitted: Boolean!
  ) {
    updatePriceQuery(
      input: { patch: $patch, priceQueryId: $id, submitted: $submitted }
    ) {
      clientMutationId
      bicyclePriceQuery {
        status
        step
        type
        ownerPostalPlace
        ownerPostalCode
        ownerPhone
        clubCode
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerAddress
        payoutBankAccount
        frameNumber
        bicycleType
        electric
        conditionWheels
        conditionTires
        conditionHeadset
        conditionGeneral
        conditionCrank
        conditionCosmeticFrame
        conditionChain
        conditionCasette
        conditionBottomBracket
        conditionDampersFront
        conditionDampersBack
        driveType
        conditionBrakes
        conditionWheelsFront
        conditionWheelsBack
        conditionTiresFront
        conditionTiresBack
        brandMotor
        breakType
        km
        conditionBattery
        conditionMotor
        offeredPrice
        retailPriceNok
        comment
      }
    }
  }
`;

const ACCEPT_CURRENT_OFFER = gql`
  mutation Update($id: UUID!) {
    acceptPriceQuery(input: { bicyclePriceQueryId: $id }) {
      clientMutationId
      bicyclePriceQuery {
        status
        step
        type
        ownerPostalPlace
        ownerPostalCode
        ownerPhone
        ownerFirstName
        ownerLastName
        ownerEmail
        ownerAddress
        payoutBankAccount
        frameNumber
        bicycleType
        electric
        conditionWheels
        conditionTires
        conditionHeadset
        conditionGeneral
        conditionCrank
        conditionCosmeticFrame
        conditionChain
        conditionCasette
        conditionBottomBracket
        conditionDampersFront
        conditionDampersBack
        conditionBattery
        conditionMotor
        offeredPrice
        retailPriceNok
        comment
        driveType
        conditionBrakes
        conditionWheelsFront
        conditionWheelsBack
        conditionTiresFront
        conditionTiresBack
        brandMotor
        breakType
        km
      }
    }
  }
`;

export const HANDED_IN = gql`
  mutation HandInBicyclePriceQuery($bicyclePriceQueryId: UUID!) {
    handInBicyclePriceQuery(
      input: { bicyclePriceQueryId: $bicyclePriceQueryId }
    ) {
      bicyclePriceQuery {
        status
        id
        calculatedSportiendaScore
        currentPrice
        offeredPrice
        retailPriceNok
      }
    }
  }
`;

export const REQUESTSIGNATURE = gql`
  mutation RequestSignaturePriceQuery($bicyclePriceQueryId: UUID!) {
    requestSignaturePriceQuery(
      input: { bicyclePriceQueryId: $bicyclePriceQueryId }
    ) {
      bicyclePriceQuery {
        status
        id
        calculatedSportiendaScore
        currentPrice
        offeredPrice
        retailPriceNok
      }
    }
  }
`;

const CREATEIMAGE = gql`
  mutation CreateImage($bicyclePriceQueryId: UUID!) {
    createPurchaseReceipt(
      input: { bicyclePriceQueryId: $bicyclePriceQueryId }
    ) {
      id
      uploadURL
      status
    }
  }
`;

const DELETEIMAGE = gql`
  mutation DeleteImage($bicyclePriceQueryId: UUID!) {
    deletePurchaseReceipt(
      input: { bicyclePriceQueryId: $bicyclePriceQueryId }
    ) {
      status
    }
  }
`;

const OPEN_STATUSES = ["DRAFT", "SUBMITTED"];
const noopValidator = () => {};

// const bikeValidator = (data) => data.bicycleType !== null;

const conditionsValidator = (data, schemaFunction) =>
  schemaFunction(data).every(([name, , , skip]) => {
    if (name === "km" && (data[name] === 0 || data[name] === "")) {
      return false;
    }
    return skip || data[name] !== null;
  });

const formatCurrency = (number) =>
  number?.toLocaleString("no-NO", {
    style: "currency",
    currency: "NOK",
    maximumFractionDigits: 0,
  });

const SellFormContext = createContext({
  id: null,
  bicycle: null,
  error: null,
  type: null,
  data: {},
  step: 0,
  loading: false,
  initialized: false,
  validationErrors: {},
  updateValidationError: async () => {},
  setStep: () => {},
  setStatus: () => {},
  setError: () => {},
  updateField: () => {},
  initialize: async () => {},
  submit: async () => {},
});

function SellFormContextProvider({ children }) {
  const { id } = useParams();
  const [data, setData] = useState({ ...initialFormState });
  const [step, setStep] = useState(0);
  const [receiptUrl, setreceiptUrl] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [offeredPrice, setOfferedPrice] = useState(0);
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [bicycle, setBicycle] = useState(null);

  const [submitForm] = useMutation(UPDATE_CURRENT_OFFER, {
    context: { clientName: "public" },
  });

  const [acceptOffer] = useMutation(ACCEPT_CURRENT_OFFER, {
    context: { clientName: "public" },
  });

  const updateData = (d) =>
    setData({
      ...data,
      ...d,
    });
  const updateValidationErrors = (d) =>
    setValidationErrors((oldValidationErrors) => {
      return { ...oldValidationErrors, ...d };
    });

  const updateField = (name) => (value) =>
    updateData({
      [name]: value,
    });

  const updateValidationError = (name) => (value) => {
    updateValidationErrors({
      [name]: value,
    });
  };

  const initialize = async (form) => {
    try {
      setLoading(true);
      // const form = await loadForm({
      //   variables: { id },
      // });
      if (form.error) throw form.error;

      const result = form?.data?.bicyclePriceQuery;

      if (result) {
        const {
          __typename,
          bicycleId,
          status,
          type,
          step,
          bicycle,
          offeredPrice,
          receiptUrl,
          contractUrl,
          ...rest
        } = result;

        setBicycle(bicycle);
        setStep(Math.max(step, 0));
        setOfferedPrice(offeredPrice);
        updateData(rest);
        setStatus(status);
        setType(type);
        setreceiptUrl(receiptUrl);
      }
      setInitialized(true);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const submit = async (saveStep) => {
    trackEvent(`PriceQuery Save Step ${step}`, {
      bicycleId: bicycle?.id,
      bicycleName: bicycle?.name,
      step: step,
      status: status,
      offeredPrice: offeredPrice,
    });
    try {
      setLoading(true);
      const between = typeof saveStep === "number";
      const submitted = status === "DRAFT" && step === 1 && saveStep === 2;

      const { retailPriceNok, ...patch } = data;
      if (between) {
        patch.step = saveStep;
      }
      const form = await submitForm({
        variables: {
          patch,
          id,
          submitted,
        },
      });

      const result = form?.data?.updatePriceQuery?.bicyclePriceQuery;
      if (result) {
        const { offeredPrice, status } = result;
        setStatus(status);
        setOfferedPrice(offeredPrice);
      }

      return submitted ? 2 : between ? saveStep : step;
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const accept = async () => {
    trackEvent(`PriceQuery Accepted`, {
      bicycleId: bicycle?.id,
      bicycleName: bicycle?.name,
      status: status,
      offeredPrice: offeredPrice,
    });
    try {
      setLoading(true);

      const form = await acceptOffer({
        variables: {
          id,
        },
      });

      const result = form?.data?.acceptPriceQuery?.bicyclePriceQuery;
      if (result) {
        const { step, status } = result;
        setStatus(status);
        setStep(step);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <SellFormContext.Provider
      value={{
        id,
        error,
        type,
        data,
        step,
        loading,
        bicycle,
        initialized,
        setStep,
        setLoading,
        updateField,
        setStatus,
        initialize,
        offeredPrice,
        receiptUrl,
        setreceiptUrl,
        submit,
        setError,
        accept,
        status,
        updateValidationError,
        validationErrors,
      }}
    >
      {children}
    </SellFormContext.Provider>
  );
}

function SellHeader({ title, children }) {
  return (
    <div className="text-center">
      <div className="text-4xl font-medium font-serif mb-4 text-sportblack">
        {title}
      </div>
      <div>{children}</div>
    </div>
  );
}

function DefaultStep({ stepFunction }) {
  const { data, updateField } = useContext(SellFormContext);
  return (
    <FormFields
      schemaFunction={stepFunction}
      data={data}
      updateField={updateField}
    />
  );
}

function SellFormPersonalDetails() {
  const {
    id,
    data,
    updateField,
    receiptUrl,
    setreceiptUrl,
    type,
    setLoading,
    bicycle,
    validationErrors,
    updateValidationError,
  } = useContext(SellFormContext);

  const [uploadImage] = useMutation(CREATEIMAGE, {
    context: { clientName: "public" },
  });
  const [deleteImage] = useMutation(DELETEIMAGE, {
    context: { clientName: "public" },
  });

  const [checkClubCode] = useCheckClubCodeLazyQuery();

  const createFieldProps = (fieldId, fieldName, required) => ({
    fieldId,
    fieldName,
    value: data[fieldId],
    onChange: updateField(fieldId),
    required:
      required === null || typeof required === "undefined" || required
        ? true
        : false,
  });
  //current year
  const currentYear = new Date().getFullYear();

  const receiptRequired = bicycle?.year && currentYear - bicycle?.year <= 3;

  // upload to s3 bucket using presign url

  const onImageChange = async (images) => {
    try {
      setLoading(true);

      for (const img of images) {
        try {
          const result = await uploadImage({
            variables: {
              bicyclePriceQueryId: id,
            },
          });
          const uploadUrl = result?.data?.createPurchaseReceipt?.uploadURL;

          if (uploadUrl) {
            const myHeaders = new Headers({ "Content-Type": img.file.type });
            const response = await fetch(uploadUrl, {
              method: "PUT",
              headers: myHeaders,
              body: img.file,
            });
          }
          setreceiptUrl(URL.createObjectURL(img));
        } catch (e) {
          // TODO: Error message
          console.error(e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onImageRemove = async (image) => {
    if (image) {
      if (window.confirm("Are you sure you want to delete this image?")) {
        await deleteImage({
          variables: {
            bicyclePriceQueryId: id,
          },
        });
        setreceiptUrl(null);
      }
    }
  };

  const imageList = [];
  if (receiptUrl) {
    imageList.push({ preview: new URL(receiptUrl) });
  }

  const validatePostalCode = (value) => {
    if (value && !/^\d{4}$/.test(value)) {
      return "Postnummer må være 4 siffer";
    }
  };

  //using regexp
  const validateEmail = (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return "Ugyldig epost";
    }
  };

  const validatePhone = (value) => {
    if (value && !/^\d{8}$/.test(value)) {
      return "Telefonnummer må være 8 siffer";
    }
  };

  const validateAccountNumber = (value) => {
    if (!norskValidator.kontonummer(value)) {
      return "Kontonummeret er ikke gyldig";
    }
  };

  const validateClubCode = async (clubCode) => {
    if (clubCode == null) return true;
    if (clubCode.length < 2) return true;

    const { data } = await checkClubCode({ variables: { clubCode: clubCode } });

    if (data?.checkClubCode === false) {
      return "PedalPartner-koden er ikke gyldig";
    }
    return null;
  };

  return (
    <div className="mt-6 grid w-3/4 grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <Field
        required
        {...createFieldProps("frameNumber", "Rammenummer")}
        type="text"
      />

      <div className="sm:col-span-6">
        <Field
          {...createFieldProps("comment", "Kommentar", false)}
          type="textarea"
        />
        <p className="mt-2 text-sm text-gray-500">
          Skriv inn sykkelens rammestørrelse. Dersom sykkelen er vesentlig
          endret fra orginalspesifikasjoner eller det mangler komponenter som
          f.eks batteri, lader eller nøkler er det viktig at du opplyser om
          dette da dette vil påvirke estimatet.
        </p>
      </div>

      <div className="sm:col-span-6">
        <Field
          {...createFieldProps("clubCode", "PedalPartner-kode", false)}
          validator={validateClubCode}
          setValidationError={updateValidationError("clubCode")}
          type="text"
        />
        <p className="mt-2 text-sm text-gray-500">
          Har kunden en PedalPartner-kode? Skriv den inn her. Denne vil gi
          Klubben en bonus.
        </p>
      </div>

      <Field
        {...createFieldProps("ownerFirstName", "Fornavn")}
        type="text"
        className="sm:col-span-3"
      />
      <Field
        {...createFieldProps("ownerLastName", "Etteravn")}
        type="text"
        className="sm:col-span-3"
      />
      <Field
        {...createFieldProps("ownerEmail", "Epost")}
        type="email"
        className="sm:col-span-3"
        validator={validateEmail}
        setValidationError={updateValidationError("ownerEmail")}
      />
      <Field
        {...createFieldProps("ownerPhone", "Telefonnummer")}
        type="tel"
        className="sm:col-span-3"
        validator={validatePhone}
        setValidationError={updateValidationError("ownerPhone")}
      />
      <Field
        {...createFieldProps("ownerAddress", "Adresse")}
        type="text"
        className="sm:col-span-6"
      />
      <Field
        {...createFieldProps("ownerPostalCode", "Postnummer")}
        type="text"
        maxLength={4}
        className="sm:col-span-2"
        setValidationError={updateValidationError("ownerPostalCode")}
        validator={validatePostalCode}
      />
      <Field
        {...createFieldProps("ownerPostalPlace", "Poststed")}
        type="text"
        className="sm:col-span-4"
      />
      {type !== "TRADEIN" && (
        <Field
          {...createFieldProps(
            "payoutBankAccount",
            "Kontonummer for utbetaling av kjøpesum",
            true
          )}
          type="text"
          className="sm:col-span-6"
          validator={validateAccountNumber}
          setValidationError={updateValidationError("payoutBankAccount")}
        />
      )}
      <div className="sm:col-span-6">
        <ImageUpload
          imageList={imageList}
          onChange={onImageChange}
          onRemoved={onImageRemove}
          label="Kjøpsdokumentasjon (Originalkvittering eller lignende)"
          required={receiptRequired}
          multiple={false}
        />
      </div>
    </div>
  );
}

function SellFormSummary() {
  const { data, bicycle, offeredPrice, status, type, accept } =
    useContext(SellFormContext);

  const onAcceptOffer = () => accept();
  const tradein = type === "TRADEIN";
  return (
    <>
      {!OPEN_STATUSES.includes(status) && <Accepted />}
      {OPEN_STATUSES.includes(status) && (
        <>
          <SellHeader title="Bekreft tilbudet">
            <div className="space-y-4">
              <p className="text-xl">
                {tradein
                  ? "Vi gir følgende tilbud basert på tilstandsvurdering av "
                  : "Vi gir deg følgende estimat basert på din tilstandsvurdering av"}{" "}
                «{bicycle?.name || "Laster skjema..."}» (sykkelen).
              </p>
              <div className="flex items-center justify-center">
                <div className="flex h-32 w-32 items-center justify-center rounded-full bg-sporticon-bg text-2xl font-semibold text-sporticon-fg">
                  {formatCurrency(offeredPrice)}
                </div>
              </div>
              {!tradein && (
                <>
                  <p>
                    Du kan du da stresse ned og lene deg tilbake. Vi vil
                    kontakte deg og avtale et hentetidspunkt som passer for deg.
                    Når vi mottar sykkelen og får bekreftet din
                    tilstandsvurdering får du kjøpsavtale til signering og
                    pengene utbetalt. Vi har forsikret sykkelen fra den hentes
                    av transportør.
                  </p>
                  <p>
                    Så, hva venter du på? Gi sykkelen din en nytt hjem, gi ditt
                    bidrag til bærekraft og gjør samtidig lommeboken glad!
                  </p>
                  <p>Vårt tilbud gjelder i 7 dager.</p>
                </>
              )}
            </div>
          </SellHeader>

          <div className="flex justify-center py-8">
            <Button onClick={onAcceptOffer}>
              {tradein
                ? "Jeg bekrefter at opplysningene er riktige og at kunden ønsker å benytte seg av tilbudet"
                : "Jeg bekrefter at opplysningene er riktige og at jeg ønsker å benytte meg av tilbudet"}
            </Button>
          </div>

          <FormSummary data={data} />
        </>
      )}
    </>
  );
}

function SellForm({ children }) {
  const form = useRef(null);
  const {
    data,
    step,
    status,
    submit,
    loading,
    setStep,
    validationErrors,
    setError,
    error,
  } = useContext(SellFormContext);
  const showButtons = OPEN_STATUSES.includes(status);
  // status === 'DRAFT' ||
  // status === 'SUBMITTED' ||
  // (step > 1 && status !== 'ACCEPTED');
  const stepComponents = [
    [DefaultStep, conditionsValidator, typesFromData],
    [DefaultStep, conditionsValidator, conditionsFromData],
    [OfferStep, noopValidator],
    [SellFormPersonalDetails, noopValidator],
    [SellFormSummary, noopValidator],
  ];

  const [CurrentForm, onValidate, stepFunction] = stepComponents[step] || [
    null,
    noopValidator,
  ];

  const scrollIntoView = () => {
    if (form.current) {
      form.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const onSubmitWrapper = async (ev) => {
    ev.preventDefault();

    scrollIntoView();

    // look for erroris in validationErrors
    const errors = Object.keys(validationErrors).filter(
      (key) => validationErrors[key]
    );
    if (errors.length > 0) {
      setError("Vennligst fyll ut alle feltene");
      return step;
    }

    if (form.current?.checkValidity() === false) {
      form.current.reportValidity();
      return;
    } else if (onValidate(data, stepFunction) === false) {
      return;
    }

    const nextStep = Math.min(steps.length, step + 1);
    setStep(await submit(nextStep));
  };

  const onBack = async () => {
    const nextStep = Math.max(0, step - 1);
    await submit(nextStep);
    scrollIntoView();
    setStep(nextStep);
  };

  return (
    <form ref={form} onSubmit={onSubmitWrapper} className="space-y-12">
      {children}

      <div>{CurrentForm && <CurrentForm stepFunction={stepFunction} />}</div>
      {step === 3 && status === "SIGNATURE_REQUESTED" && (
        <FormButton type="save" disbled={loading} onClick={onSubmitWrapper} />
      )}
      {error && <p className="text-red-500">{error}</p>}
      {showButtons && (
        <div className="flex space-x-2">
          {step > 0 && status === "DRAFT" && (
            <FormButton type="previous" disabled={loading} onClick={onBack} />
          )}

          {step < steps.length - 1 && (
            <FormButton
              type="next"
              disbled={loading}
              onClick={onSubmitWrapper}
            />
          )}
        </div>
      )}
    </form>
  );
}

function Rejected() {
  const { id, data, bicycle } = useContext(SellFormContext);

  return (
    <>
      <SellHeader title="Din sykkel ble dessverre avvist">
        <div className="space-y-4">
          <p className="text-xl">
            Dette er basert på din tilstandsvurdering av «
            {bicycle?.name || "Laster skjema..."}» (sykkelen).
          </p>
          <p>
            Vårt utgangspunkt er at sykkelen hadde en veiledende pris når den
            var ny på {formatCurrency(data?.retailPriceNok)}.
          </p>
          <p>
            <a
              className="underline"
              href={`mailto:team@sportienda.com?subject=Wrong retail price on "${bicycle?.name}"`}
            ></a>
            <PopupButton
              buttonLabel="Hvorfor ble sykkelen avvist?"
              popupTitle="Hvorfor ble sykkelen avvist?"
            >
              <p className="text-left my-4">
                Det kan være mange ulike grunner som til sammen gjør at sykkelen
                din blir avvist av Sportienda. Våre algoritmer som beregner
                estimert kjøps eller innbytteverdi hensyntar opprinnelig
                veiledende pris fra leverandør, alder på sykkelen og sykkelens
                tilstand. I tillegg har vi mange ulike datakilder som gir
                innsikt i sykkelmerkets attraktivitet i bruktmarkedet kombinert
                med blant annet sesongsvingninger som påvirker bruktprisene og
                flere andre faktorer. Dette medfører at du kan oppleve at
                sykkelen blir avvist på høsten, selv om du mottok et estimat
                under høysesong. Dette er også grunnen til at våre
                tilbud/estimat kun er gyldige i 7 dager.
              </p>

              <p className="text-left my-4">
                Dersom beregningene resulterer i en kjøpesum hvor våre kostnader
                knyttet til prosessene med å kjøpe, gjennomføre service og
                sertifisere/reparere sykkelen tar en vesentlig andel av verdien
                blir sykkelen automatisk avvist. Vi mener det i disse tilfellene
                vil være såpass stor prosentmessig forskjell på hva vi kan tilby
                for sykkelen og hva du selv kan oppnå ved å selge den i
                privatmarkedet at vi ikke lenger kan kalle det en “fair pris”.{" "}
              </p>

              <p className="text-left my-4">
                Når sykkelens verdi blir såpass lav reduseres også risikoen som
                salg i privatmarkedet innebærer.
              </p>

              <p className="text-left my-4">
                Vi håper du i disse tilfellene selv kan finne en ny bruker som
                vil gi sykkelen et nytt liv
              </p>
            </PopupButton>
          </p>
          <p>
            <a
              className="underline"
              href={`mailto:team@sportienda.com?subject=Wrong retail price on "${bicycle?.name}"`}
            >
              Rapporter feil ved prisestimat
            </a>
          </p>
        </div>
      </SellHeader>
    </>
  );
}

const testimonials = [
  {
    quote:
      "...Etter kort tid fikk jeg tilbud på sykkelen, som jeg kunne \
      godkjenne med BankID, og senere samme dag sto pengene på konto. \
      Veldig enkel, trygg og behagelig måte å selge sykkel på!",
    name: "Thor",
    source: "Trustpilot",
  },
  {
    quote:
      "...En god sykkel får nytt hjem og jeg kunne kjøpe en ny sykkel som passet meg bedre.",
    name: "Morten",
    source: "Trustpilot",
  },
  {
    quote:
      "Kan absolutt anbefale å selge sykkelen hos Sportienda. Forenkler salg av bruktsykkel så til de grader. Du kan kanskje få mer for sykkelen ved å selge privat, men jeg synes pristilbudet til Sportienda var godt. Dessuten får du solgt sykkelen omtrent på dagen, avhengig av hvor du bor. I tillegg til at jeg nå slipper å bekymre meg for om kjøper kommer tilbake med reklamasjoner e.l. på sykkelen.",
    name: "Jøran Tvedt",
    source: "Trustpilot",
  },
];

function Testimonials() {
  return (
    <div className="grid grid-cols-2 gap-3 md:grid-cols-1 text-left ">
      {testimonials.map((testimonial, idx) => (
        <section
          key={idx}
          className={classNames(
            idx > 1 ? "hidden lg:block" : "",
            "px-2 py-3 lg:px-3"
          )}
        >
          <figure className="mx-auto max-w-2xl">
            <p className="sr-only">5 out of 5 stars</p>
            <div className="flex gap-x-1 text-sportyellow">
              <StarIcon className="h-4 w-4 flex-none" aria-hidden="true" />
              <StarIcon className="h-4 w-4 flex-none" aria-hidden="true" />
              <StarIcon className="h-4 w-4 flex-none" aria-hidden="true" />
              <StarIcon className="h-4 w-4 flex-none" aria-hidden="true" />
              <StarIcon className="h-4 w-4 flex-none" aria-hidden="true" />
            </div>
            <blockquote className="mt-3 text-sm font-semibold  tracking-tight text-gray-900 sm:text-md ">
              <p>“{testimonial.quote}”</p>
            </blockquote>
            <figcaption className="mt-3 flex gap-x-6">
              <div className="text-sm ">
                <span className="font-semibold text-gray-900">
                  {testimonial.name}
                </span>
                ,{" "}
                <span className="mt-0.5 text-gray-600">
                  {testimonial.source}
                </span>
              </div>
            </figcaption>
          </figure>
        </section>
      ))}
    </div>
  );
}

function SellQualified() {
  const { data, bicycle, offeredPrice, type, setStep, submit, step, loading } =
    useContext(SellFormContext);

  const tradein = type === "TRADEIN";

  const accept = async () => {
    const nextStep = Math.min(steps.length, step + 1);
    setStep(await submit(nextStep));
  };

  return (
    <div className="bg-white">
      <div className="relative isolate overflow-hidden bg-white px-6 py-16 text-center grid md:grid-cols-3 grid-cols-1 gap-5">
        <div className="col-span-2">
          <SellHeader
            title={
              tradein
                ? "Hurra! Sykkelen er kvalifisert"
                : "Hurra! Din sykkel er kvalifisert"
            }
          >
            <div className="space-y-4">
              <p className="text-xl">
                {tradein
                  ? "Vi gir følgende tilbud basert på tilstandsvurdering av"
                  : "Vi gir deg følgende estimat basert på din tilstandsvurdering av"}{" "}
                «{bicycle?.name || "Laster skjema..."}» (sykkelen).
              </p>
              <div className="flex items-center justify-center flex-col gap-5">
                <div className="flex h-32 w-32 items-center justify-center rounded-full bg-sporticon-bg text-2xl font-semibold text-sporticon-fg">
                  {formatCurrency(offeredPrice)}
                </div>
                <FormButton
                  type="nextPersonalDetails"
                  disabled={loading}
                  onClick={accept}
                />
              </div>

              {tradein ? (
                <p>
                  Vårt tilbud gjelder i 7 dager. Om kunden ønsker å benytte
                  tilbudet: klikk "neste" og registrerer rammenummer og
                  personalia før du bekrefter aksept av tilbudet og sender
                  signeringsforespørselen til kunden.
                </p>
              ) : (
                <>
                  <h2 className="text-center font-bold text-xl">
                    Fri frakt – Hurtig oppgjør – Ingen reklamasjon
                  </h2>
                  <p>
                    Vårt tilbud gjelder i 7 dager. Hvis du ønsker å benytte
                    tilbudet klikker du neste og registrerer rammenummer og
                    personalia før du bekrefter aksept av tilbudet.
                  </p>
                  <p>
                    Du kan du da stresse ned og lene deg tilbake. Vi vil
                    kontakte deg og avtale et hentetidspunkt som passer for deg.
                    Når vi mottar sykkelen og får bekreftet din
                    tilstandsvurdering får du kjøpsavtale til signering og
                    pengene utbetalt.
                  </p>
                  <p>
                    Så, hva venter du på? Gi sykkelen din en nytt hjem, gi ditt
                    bidrag til bærekraft og gjør samtidig lommeboken glad!
                  </p>
                </>
              )}
              <p>
                {tradein
                  ? "Prisen er basert på oppgitt tilstandsvurdering og våre data om sykkelen som er registrert. Vårt utgangspunkt er at sykkelen hadde en veiledende pris når den var ny på"
                  : "Prisen er basert på din tilstandsvurdering og våre data om sykkelen du har registrert. Vårt utgangspunkt er at sykkelen hadde en veiledende pris når den var ny på"}{" "}
                {formatCurrency(data?.retailPriceNok)}.
              </p>

              <p>
                <a
                  className="underline"
                  href={`mailto:team@sportienda.com?subject=Wrong retail price on "${bicycle?.name}"`}
                >
                  Raporter feil ved prisestimat
                </a>
              </p>
            </div>
          </SellHeader>
        </div>
        <div>
          <Testimonials />
        </div>
      </div>
      {/* <div className="mt-8">
        <UserAdSplash />
      </div> */}
    </div>
  );
}

function OfferStep() {
  const { status } = useContext(SellFormContext);

  return status === "REJECTED" ? <Rejected /> : <SellQualified />;
}

function Accepted() {
  const { data, status, bicycle, offeredPrice, type } =
    useContext(SellFormContext);
  const tradein = type === "TRADEIN";

  return (
    <>
      {status === "ACCEPTED" && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Takk, vi kontakter deg for transport
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  Vi vil kontakte deg og avtale et hentetidspunkt som passer for
                  deg. Når vi mottar sykkelen og får bekreftet din
                  tilstandsvurdering får du kjøpsavtale til signering og pengene
                  utbetalt.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {status === "HANDED_IN" && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Sykkelen er levert til butikk
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  Tusen takk for at du valgte å la Sportienda gi din gamle
                  sykkel ett nytt liv!
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {status === "SIGNED" && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Signert og godtatt
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>Estimatet er signert og godtatt.</p>
              </div>
              {/* <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">



              <button
                type="button"
                disabled={saveLoading}
                onClick={onRequestSignature}
                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                Last ned avtale
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      )}

      {status === "SIGNATURE_REQUESTED" && (
        <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Vi venter på {tradein ? "kundens" : "din"} signatur
              </h3>
              <div className="mt-2 text-sm text-green-700">
                {tradein ? (
                  <>
                    <p>
                      Vi har nå sendt kunden en epost til '{data?.ownerEmail}'
                      med lenke til kjøpsavtale som må aksepteres og signeres
                      med BankID.
                    </p>
                  </>
                ) : (
                  <p>
                    Det er sendt en epost til {data?.ownerEmail} med en link for
                    signering. Er den ikke kommet frem, sjekk spam.
                  </p>
                )}
              </div>
              {/* <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">



              <button
                type="button"
                disabled={saveLoading}
                onClick={onRequestSignature}
                className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                Send epost på nytt
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      )}
      <div className="mt-10">
        <SellHeader title="Prisestimat">
          <div className="space-y-4">
            <p className="text-xl">
              {tradein
                ? "Vi gir følgende tilbud basert på tilstandsvurdering av"
                : "Vi har gitt deg følgende estimat basert på din tilstandsvurdering av"}{" "}
              «{bicycle?.name || "Laster skjema..."}» (sykkelen).
            </p>
            <div className="flex items-center justify-center">
              <div className="flex h-32 w-32 items-center justify-center rounded-full bg-sporticon-bg text-2xl font-semibold text-sporticon-fg">
                {formatCurrency(offeredPrice)}
              </div>
            </div>
            {tradein ? (
              <p>
                Vi vil kontakte deg og avtale et hentetidspunkt som passer så
                snart vi har registrert at kunden har signert kjøpsavtalen og du
                har registrert sykkelen mottatt i butikken. Dere mottar betaling
                på registrerert kontonummer og kvittering på epost.
              </p>
            ) : (
              <p>
                Prisen er basert på din tilstandsvurdering og våre data om
                sykkelen du har registrert. Vårt utgangspunkt er at sykkelen
                hadde en veiledende pris når den var ny på{" "}
                {formatCurrency(data?.retailPriceNok)}.
              </p>
            )}

            <p>
              <a
                className="underline"
                href={`mailto:team@sportienda.com?subject=Wrong retail price on "${bicycle?.name}"`}
              >
                Raporter feil ved prisestimat
              </a>
            </p>
          </div>
        </SellHeader>
      </div>
    </>
  );
}

function SellPage() {
  const { id, initialize, bicycle, step, error, initialized } =
    useContext(SellFormContext);

  // const currentStep = status === 'ACCEPTED' ? steps.length : step;

  const form = useQuery(LOAD_INITIAL_OFFER, {
    context: { clientName: "public" },
    variables: { id },
  });
  useEffect(() => {
    initialize(form);
  }, [form]);
  if (form?.loading) {
    return <Loading />;
  }
  if (!form.loading && !bicycle?.name) {
    return <NotFound />;
  }
  const bicycleName = bicycle?.name || "Laster skjema...";
  return (
    <>
      {!initialized && <div>Laster skjema...</div>}

      {initialized && (
        <>
          <div>
            <div className="lg:hidden">
              <CompactSteps steps={steps} current={step} />
            </div>
            <div className="hidden lg:block">
              <Steps steps={steps} current={step} />
            </div>
          </div>
          <div className="mx-4 xl:mx-0">
            <SellForm>
              {/* TODO: Error component */}
              {/* {error && <div>{error}</div>} */}
            </SellForm>
          </div>
        </>
      )}
    </>
  );
}

export function TradeIn() {
  const [requestSignature] = useMutation(REQUESTSIGNATURE);
  const [handIn] = useMutation(HANDED_IN);

  const [saveLoading, setLoading] = useState(false);
  const onError = (err) => console.error(err);
  const { id, status, setStatus, step, setStep } = useContext(SellFormContext);

  const requestWrapper = async (fn) => {
    try {
      setLoading(true);
      return await fn();
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  const onRequestSignature = async (ev) => {
    ev.preventDefault();

    const data = await requestWrapper(() =>
      requestSignature({
        variables: {
          bicyclePriceQueryId: id,
        },
      })
    );

    data?.data?.requestSignaturePriceQuery?.bicyclePriceQuery?.status &&
      setStatus(
        data?.data?.requestSignaturePriceQuery?.bicyclePriceQuery?.status
      );
  };

  const onHandIn = async (ev) => {
    ev.preventDefault();

    const data = await requestWrapper(() =>
      handIn({
        variables: {
          bicyclePriceQueryId: id,
        },
      })
    );

    data?.data?.handInBicyclePriceQuery?.bicyclePriceQuery?.status &&
      setStatus(data?.data?.handInBicyclePriceQuery?.bicyclePriceQuery?.status);
  };

  const showRequestSignature =
    status === "ACCEPTED" || status === "SIGNATURE_REQUESTED";
  console.log(showRequestSignature);
  return (
    <>
      <div className="space-y-4 rounded-lg bg-white px-4 py-6 m-4  shadow-sportbox max-w-7xl">
        <SellPage />
      </div>

      {(showRequestSignature || status === "SIGNED") && (
        <div className="space-y-4 rounded-lg bg-white px-4 py-6 m-4  shadow-sportbox max-w-7xl">
          {showRequestSignature && (
            <>
              <div className="pr-3">
                Dersom kunde ikke har mottatt epost med lenke til kjøpsavtalen
                som må aksepteres og signeres? Klikk her for å sende på nytt.
              </div>
              <FormButton
                type="requestSignature"
                disabled={saveLoading}
                onClick={onRequestSignature}
              />
              <div>
                {" "}
                Feil i epostadressen?{" "}
                <button
                  type="button"
                  onClick={() => setStep(step - 1)}
                  className=" text-sportgreen hover:underline"
                >
                  Endre e-post
                </button>{" "}
                (Du må sende signerings-epost på nytt etter at du har lagret){" "}
              </div>
            </>
          )}
          {status === "SIGNED" && (
            <FormButton
              type="handIn"
              disabled={saveLoading}
              onClick={onHandIn}
            />
          )}
        </div>
      )}
    </>
  );
}

export function TradeInWrapper() {
  return (
    <SellFormContextProvider>
      <TradeIn />
    </SellFormContextProvider>
  );
}

export default function Sell() {
  // TODO: Use a template
  return (
    <div className="mx-auto max-w-7xl py-8">
      <SellFormContextProvider>
        <SellPage />
      </SellFormContextProvider>
    </div>
  );
}
