import BikeIcon from "../../media/images/bike.svg";

/* eslint-disable react/prop-types */

export default function BikeNotFound({ id }) {
  return (
    <div className="p-8">
      <div className="flex items-center border border-red-300 bg-red-100 p-4">
        <div className="w-72 opacity-50">
          <img alt="" src={BikeIcon} />
        </div>
        <div className="grow text-center">
          <header className="text-2xl font-bold">
            The object was not found
          </header>
          <article>ID: {id}</article>
        </div>
      </div>
    </div>
  );
}
