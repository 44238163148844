import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftOnRectangleIcon,
  ArrowPathRoundedSquareIcon,
  CalendarIcon,
  DocumentTextIcon,
  HomeIcon,
  LockClosedIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { TableCellsIcon } from "@heroicons/react/24/solid";
import config from "../../config";
import { classNames } from "../../utils/dom";
import Logo from "./Logo.svg";

const SPORT_AND_BIRK = config.admin_orgs;
const SPORT = [config.org];

const sidebarNavigation = [
  {
    name: "Dashboard",
    href: "",
    exact: true,
    org_id: SPORT,
    icon: HomeIcon,
    current: true,
  },
  {
    name: "Trello",
    href: "trello",
    exact: true,
    org_id: SPORT,
    icon: CalendarIcon,
    current: true,
  },
  {
    name: "Innbytte",
    href: "tradein",
    exact: false,
    icon: ArrowPathRoundedSquareIcon,
    current: false,
  },
  {
    name: "Prisforespørsler",
    href: "pricequery",
    exact: false,
    icon: QuestionMarkCircleIcon,
    org_id: SPORT,
    current: false,
  },
  {
    name: "Inntak",
    href: "intake",
    exact: false,
    icon: ArrowLeftOnRectangleIcon,
    org_id: SPORT,
    current: false,
  },
  {
    name: "Salgsplakater",
    href: "inventory",
    exact: false,
    icon: ShoppingCartIcon,
    org_id: SPORT,
    current: false,
  },
  {
    name: "Order",
    href: "order",
    exact: false,
    icon: ShoppingBagIcon,
    current: false,
    org_id: SPORT,
  },

  {
    name: "Bruktprotokoll",
    href: "protocol",
    exact: false,
    icon: LockClosedIcon,
    org_id: SPORT,
    current: false,
  },

  {
    name: "Value/year",
    href: "value-by-year",
    exact: false,
    org_id: SPORT,
    icon: TableCellsIcon,
    current: false,
  },
  {
    name: "CMS",
    href: "cms",
    exact: false,
    org_id: SPORT,
    icon: DocumentTextIcon,
    current: false,
  },
];

export default function Sidebar({ mobileMenuOpen, setMobileMenuOpen, user }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    setMobileMenuOpen(false);
  }, [navigate]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full overflow-hidden">
        ```
      */}
      {/* Narrow sidebar */}
      <div className="hidden w-28 bg-sportgrey overflow-y-auto md:block">
        <div className="w-full py-6 flex flex-col items-center">
          <Link to="../" className="w-1/2 ">
            <img src={Logo} />
          </Link>
          <div className="flex-1 mt-6 w-full px-2 space-y-1">
            {sidebarNavigation
              .filter(
                (item) => !item.org_id || item.org_id.includes(user.org_id)
              )
              .map((item) => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  end={item.exact}
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? "bg-sportblack text-white"
                        : "hover:bg-sportblack text-sportgreen hover:text-white",
                      "group w-full p-2 rounded-md flex flex-col items-center text-xs font-medium"
                    )
                  }
                >
                  <item.icon
                    className=" group-hover:text-white h-6 w-6"
                    aria-hidden="true"
                  />
                  <span className="mt-2">{item.name}</span>
                </NavLink>
              ))}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="md:hidden" onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full bg-sportgrey pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XCircleIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 px-4 flex items-center text-xl text-sportgreen font-serif  ">
                  PIM
                </div>
                <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                  <nav className="h-full flex flex-col">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          end={item.exact}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-sportblack text-white"
                                : "text-sportgreen hover:bg-sportblack hover:text-white",
                              "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                            )
                          }
                        >
                          <item.icon
                            className="mr-3 h-6 w-6"
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </NavLink>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
