import { useSearchParams } from 'react-router-dom';

export function usePaginationTableState({
  defaultOrderBy = 'CREATED_DESC',
  filters,
}) {
  const [searchParams] = useSearchParams();
  const offset = +searchParams.get('offset') || 0;
  const orderBy = searchParams.get('orderBy') || defaultOrderBy;

  let filter = undefined;
  if (filters) {
    const entries = Object.entries(filters)
      .filter(([k, v]) => typeof v !== 'function' || searchParams.has(k))
      .map(([k, v]) => {
        if (typeof v === 'function') {
          const q = searchParams.get(k);
          return [k, v(q, k)];
        }

        return [k, v];
      });

    if (entries.length > 0) {
      filter = Object.fromEntries(entries);
    }
  }

  return {
    filter,
    offset,
    orderBy,
  };
}
