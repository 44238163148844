import { gql, useQuery } from '@apollo/client';

const QUERY = gql`
query BicycleValueByYearQuery {
    bicycleValueByYears {
      nodes {
        nodeId
        year
        priceFrom
        discountPct
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
}  
`;










export function useBicycleValueByYear() {
    const result = useQuery(QUERY, {
        // variables: { filter, orderBy },
        nextFetchPolicy: 'cache-and-network',
    });

    var loading = result.loading

    var valuesByYear = result?.data?.bicycleValueByYears?.nodes ?? [];
    const { totalCount = 0 } = result?.data?.bicycleValueByYears ?? {};   

    return {
        ...result,
        loading,
        valuesByYear,
        totalCount,
    };
}
