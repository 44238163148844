import { gql, useQuery } from "@apollo/client";
import { usePaginationTableState } from "../../../hooks/usePaginationTable";

const RECIPES = gql`
  query GetInventories(
    $filter: InventoryFilter
    $pagesize: Int
    $offset: Int
    $orderBy: [InventoriesOrderBy!]
  ) {
    inventories(
      filter: $filter
      first: $pagesize
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        id
        name
        status
        created
        description
        objectType
        type
        bicycleIntakeForm {
          id
          sku
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export function useInventories(pagesize) {
  const { filter, offset, orderBy } = usePaginationTableState({
    filters: {
      name: (v) => ({ likeInsensitive: `%${v}%` }),
      status: (v) => ({ equalTo: v }),
    },
  });

  const result = useQuery(RECIPES, {
    variables: { filter, pagesize, offset, orderBy },
    nextFetchPolicy: "cache-and-network",
  });

  var inventories = result.data?.inventories?.nodes ?? [];
  if (!inventories) {
    inventories = [];
  }
  const { totalCount = 0 } = result?.data?.inventories ?? {};

  return { ...result, inventories, totalCount };
}
