import {
  useAssociatedClubQuery,
  useAssociatedClubsQuery,
  useCreateAssociatedClubMutation,
  useDeleteImageMutation,
  useUpdateAssociatedClubMutation,
  useUploadImageMutation,
} from "@app/graphql/pim";
import { FieldType, Form, ItemSchema, ListColumn } from "@app/types";
import { z } from "zod";

export const addInitialValues = {
  name: null,
  fikenId: null,
  purchaseBonusCode: null,
};

export const addForm: Form = [
  {
    id: "name",
    name: "Klubbnavn",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "fikenId",
    name: "Fiken Levrandørnummersen",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "purchaseBonusCode",
    name: "Kjøpsbonuskode",
    type: FieldType.text,
    colspan: 4,
    required: false,
  },
];

export const editForm: Form = [
  ...addForm,
  {
    id: "logo",
    name: "Logo",
    type: FieldType.image,
    colspan: 4,
    required: false,
    zFieldType: z.string().optional(),
    fieldProps: {
      useUploadImageMutation,
      useDeleteImageMutation,
      forType: "associatedClub",
    },
  },
  {
    id: "description",
    name: "Beskrivelse",
    type: FieldType.text,
    colspan: 4,
    required: false,
    zFieldType: z.string().optional(),
  },

  {
    id: "contactEmail",
    name: "Kontakt e-post",
    type: FieldType.text,
    colspan: 3,
    required: false,
  },
  {
    id: "contactPhone",
    name: "Kontakt telefon",
    type: FieldType.text,
    colspan: 3,
    required: false,
  },
  {
    id: "contactName",
    name: "Kontakt navn",
    type: FieldType.text,
    colspan: 6,
    required: false,
  },
  {
    id: "address",
    name: "Adresse",
    type: FieldType.text,
    colspan: 6,
    required: false,
  },
  {
    id: "postalCode",
    name: "Postnummer",
    type: FieldType.text,
    colspan: 2,
    required: false,
  },
  {
    id: "postalPlace",
    name: "Sted",
    type: FieldType.text,
    colspan: 4,
    required: false,
  },
  {
    id: "published",
    name: "Publisert",
    type: FieldType.boolean,
    required: false,
    zFieldType: z.boolean().optional(),
  },
];

export const editInitialValues = {
  ...addInitialValues,
  description: null,
  published: false,
  contactEmail: null,
  contactPhone: null,
  contactName: null,
  address: null,
  postalCode: null,
  postalPlace: null,
  logo: null,
};

export const listColumns: ListColumn[] = [
  {
    id: "name",
    name: "Klubbnavn",
  },

  {
    id: "purchaseBonusCode",
    name: "Purchase Bonus Code",
  },
  {
    id: "published",
    name: "Publisert",
    parseFormData: (data) => (data ? "Yes" : "No"),
  },
  {
    id: "created",
    name: "Opprettet",
  },
  {
    id: "lastUpdated",
    name: "Oppdatert",
  },
];

const associatedClub: ItemSchema = {
  id: "associatedClub",
  name: "Tilknytted Klubb",
  plural: "Tilknyttede Klubber",
  listColumns,
  addForm,
  editForm,
  addInitialValues,
  editInitialValues,
  useItemsQuery: useAssociatedClubsQuery,
  useItemQuery: useAssociatedClubQuery,
  useCreateItemMutation: useCreateAssociatedClubMutation,
  useUpdateItemMutation: useUpdateAssociatedClubMutation,
  parseItem: (data) => data?.associatedClub || {},
  parseItems: (data) =>
    ({
      totalCount: data?.associatedClubs?.totalCount,
      items: data?.associatedClubs?.nodes,
    } || []),
  createInputFromValues: (values) => ({
    associatedClub: values,
  }),
};

export default associatedClub;
