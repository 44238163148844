import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useSearchParams } from "react-router-dom";

export default function SearchField({
  name,
  placeholder,
}: {
  name: string;
  placeholder: string;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(name) || "";

  const handleChange = ({ id }: { id: any }) => {
    id === "" ? searchParams.delete(name) : searchParams.set(name, id);
    searchParams.delete("offset");
    setSearchParams(searchParams);
  };

  return (
    <div className="flex-grow relative flex py-1">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
      <MagnifyingGlassIcon
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        id="search-field"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder={placeholder}
        type="search"
        name={name}
        defaultValue={paramValue}
        //when clicking enter
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleChange({ id: e.currentTarget.value });
          }
        }}
      />
    </div>
  );
}
