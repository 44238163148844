import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

export function useNotification() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()

  if (!searchParams.has('ts')) {
    searchParams.append('ts', Date.now())
  }

  const to = `${pathname}?${searchParams.toString()}`

  const showNotification = (notification) => {
    const state = { notification }
    navigate(to, { state })
  }

  return { showNotification }
}
