import { useSearchParams } from 'react-router-dom'

export default function Pagination({ totalCount, pageSize }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const offset = +searchParams.get('offset') ?? 0
  const from = offset + 1
  const to = offset + pageSize > totalCount ? totalCount : offset + pageSize

  const handleClick = (dir) => {
    searchParams.set('offset', offset + pageSize * dir)
    setSearchParams(searchParams)
  }

  return (
    <nav
      className="bg-gray-50 px-4 py-3 flex items-center justify-between"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        {totalCount > 0 && (
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium text-gray-900">{from}</span> to{' '}
            <span className="font-medium text-gray-900">{to}</span> of{' '}
            <span className="font-medium text-gray-900">{totalCount}</span>{' '}
            results
          </p>
        )}
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          onClick={() => handleClick(-1)}
          disabled={offset === 0}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        >
          Previous
        </button>
        <button
          onClick={() => handleClick(1)}
          disabled={offset + pageSize > totalCount}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        >
          Next
        </button>
      </div>
    </nav>
  )
}
