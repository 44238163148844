import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { dateTimeFormat } from "../../utils/dates";
import { classNames } from "../../utils/dom";
import { useSelectAll } from "../../utils/selection";
// import FilterText from '../../components/atom/FilterText'
import FilterSingle from "../../components/atom/FilterSingle";
import Pagination from "../../components/atom/Pagination";
import SelectedRows from "../../components/atom/SelectedRows";
import SortableColumn from "../../components/atom/SortableColumn";
import useEnum from "../../utils/enum";
import { useBicyclePriceQueries } from "./hooks/useBicyclePriceQueries";
import BulkActions from "./partials/BulkActions";

const PAGESIZE = 20;

export default function PriceQueryList({ type }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const STATUS = useEnum("PriceQueryStatus");
  const TYPE = useEnum("PriceQueryType");
  const { data, pricequeries, totalCount } = useBicyclePriceQueries(
    PAGESIZE,
    type
  );
  const selectAllRef = useRef();
  const { selected, setSelected, selection } = useSelectAll(
    selectAllRef,
    pricequeries
  );

  return (
    <div className="px-6">
      <div className="grid grid-cols-1">
        <div className="row-start-1 flex flex-wrap justify-start mb-3 text-sm">
          {/* <div className="relative mr-3 mb-3">
            <FilterText name="name" placeholder={t('Search pricequeries')} />
          </div> */}
          <div className="mr-3 mb-3">
            <FilterSingle name="status" values={STATUS} />
          </div>
          {!type && (
            <div className="mr-3 mb-3">
              <FilterSingle name="type" values={TYPE} />
            </div>
          )}
        </div>
        <div className="row-start-1 flex justify-end mb-6 text-sm">
          <BulkActions selected={selected} selection={selection} data={data} />
        </div>
      </div>
      <SelectedRows
        selected={selected}
        setSelected={setSelected}
        selection={selection}
        totalCount={totalCount}
        pageSize={PAGESIZE}
      />
      <div className="overflow-hidden mb-10 border border-gray-200 sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-50 text-left text-xs text-gray-900 uppercase tracking-wider">
                <th className="sticky left-0 z-10 px-4 py-4 bg-inherit font-medium">
                  <SortableColumn field="OWNER_EMAIL">
                    <input
                      name="select-all"
                      type="checkbox"
                      className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      value="current-page"
                      checked={
                        selected.length > 0 &&
                        (selected.length >= PAGESIZE ||
                          selected.length === totalCount)
                      }
                      onChange={setSelected}
                      ref={selectAllRef}
                    />
                  </SortableColumn>
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="OWNER_LAST_NAME" />
                </th>

                <th className="px-4 py-4 font-medium">Bicycle</th>

                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="TYPE" />
                </th>

                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="STATUS" />
                </th>

                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="LAST_UPDATED" />
                </th>

                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="CREATED" />
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {pricequeries.map((pricequery) => (
                <tr
                  key={pricequery.id}
                  onClick={() => navigate(pricequery.id)}
                  className={classNames(
                    { "bg-indigo-50": selected.includes(pricequery.id) },
                    "text-sm text-gray-500 whitespace-nowrap bg-white hover:bg-indigo-50 cursor-pointer border-t border-gray-100"
                  )}
                >
                  <td className="sticky relative left-0 z-10 max-w-xs sm:max-w-sm px-4 py-3 bg-inherit font-medium text-gray-900 text-left text-ellipsis overflow-hidden">
                    {selected.includes(pricequery.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )}
                    <input
                      name="pricequery"
                      type="checkbox"
                      onClick={(event) => event.stopPropagation()}
                      className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      value={pricequery.id}
                      checked={selected.includes(pricequery.id)}
                      onChange={setSelected}
                    />
                    {pricequery.ownerEmail}
                  </td>

                  <td className="px-4 py-3">
                    {pricequery.ownerFirstName} {pricequery.ownerLastName}
                  </td>

                  <td className="px-4 py-3">{pricequery?.bicycle?.name}</td>
                  <td className="px-4 py-3">{pricequery?.type}</td>

                  <td className="px-4 py-3">{pricequery?.status}</td>

                  <td className="px-4 py-3 tabular-nums">
                    {pricequery.lastUpdated &&
                      dateTimeFormat(pricequery.lastUpdated)}
                  </td>

                  <td className="px-4 py-3 tabular-nums">
                    {pricequery.created && dateTimeFormat(pricequery.created)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination totalCount={totalCount} pageSize={PAGESIZE} />
      </div>
    </div>
  );
}
