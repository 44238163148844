import type { Option } from "@app/types";
import { Combobox, Listbox, RadioGroup, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../utils/dom";

import { ImageUpload } from "../../components/common/ImageUpload";

export function Input({
  type,
  name,
  autoComplete,
  placeholder,
  parseFormData,
  children,
  required,
  value,
  onChange,
}: {
  type: string;
  name: string;
  autoComplete?: string;
  placeholder?: string;
  parseFormData?: (data: any) => any;
  children: string;
  required?: boolean;
  value?: any;
  onChange: (field: string, value: any) => void;
}) {
  const update = (e: any) => {
    onChange(
      name,
      parseFormData ? parseFormData(e.target.value) : e.target.value
    );
  };
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {children}
      </label>
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          value={value}
          onChange={update}
          required={required}
          placeholder={placeholder}
          autoComplete={autoComplete}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sportdarkgreen sm:text-sm sm:leading-6"
        />
      </div>
    </>
  );
}

export function TextAreaInput({
  name,
  children,
  value,
  onChange,
}: {
  name: string;
  children: string;
  value: string;
  onChange: (field: string, value: any) => void;
}) {
  const update = (e: any) => {
    onChange(name, e.target.value);
  };

  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {children}
      </label>
      <div className="mt-2">
        <textarea
          name={name}
          id={name}
          value={value}
          onChange={update}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sportdarkgreen sm:text-sm sm:leading-6"
        />
      </div>
    </>
  );
}

export function Text({
  children,
  value,
  parseFormData,
}: {
  children: string;
  value: string;
  parseFormData?: (data: any) => any;
}) {
  return (
    <div>
      <div className="text-sm font-medium leading-6 text-gray-900">
        {children}
      </div>

      <div className=" w-full mt-1 ml-1  text-gray-600 sm:text-sm sm:leading-6">
        {parseFormData ? parseFormData(value) : value}
      </div>
    </div>
  );
}

export function BooleanInput({
  name,
  children,
  description,
  checked,
  onChange,
}: {
  name: string;
  children: string;
  description?: string;
  checked: boolean;
  onChange: (field: string, value: any) => void;
}) {
  const update = (e: any) => {
    onChange(name, e.target.checked);
  };
  return (
    <fieldset>
      <label htmlFor={name} className="block mt-2">
        <div className="relative flex items-start ">
          <div className="mr-3 flex h-6 items-center">
            <input
              id={name}
              name={name}
              type="checkbox"
              checked={checked}
              onChange={update}
              className="h-5 w-5 rounded-md border-gray-300 text-sportdarkgreen focus:ring-sportgreen"
            />
          </div>
          <div className="min-w-0 flex-1 text-md leading-6">
            <span className="select-none font-medium text-gray-900">
              {children}
            </span>
          </div>
        </div>
        {description && (
          <div className="relative flex items-start py-1 ml-8  text-sm">
            {description}
          </div>
        )}
      </label>
    </fieldset>
  );
}

const GRID_COLS = [
  "sm:grid-cols-1",
  "sm:grid-cols-2",
  "sm:grid-cols-3",
  "sm:grid-cols-4",
  "sm:grid-cols-5",
  "sm:grid-cols-6",
  "sm:grid-cols-7",
  "sm:grid-cols-8",
  "sm:grid-cols-9",
  "sm:grid-cols-10",
  "sm:grid-cols-11",
  "sm:grid-cols-12",
];

export function ImageInput({
  name,
  itemId,
  children,
  description,
  value,
  required,
  onChange,
  fieldProps,
}: {
  name: string;
  children: React.ReactNode;
  description?: string;
  value?: any;
  required?: boolean;
  itemId: string | null;
  onChange: (field: string, value: any) => void;
  fieldProps?: any;
}) {
  const [uploadImage] = fieldProps.useUploadImageMutation();
  const [deleteImage] = fieldProps.useDeleteImageMutation();
  const [loading, setLoading] = useState(false);
  const update = async (images: [any]) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);

      for (const img of images) {
        try {
          const {
            data: {
              uploadImage: { uploadURL },
            },
          } = await uploadImage({
            variables: {
              id: itemId,
              forType: fieldProps.forType,
            },
          });

          if (uploadURL) {
            let formData = new FormData();
            formData.append("file", img.file);
            await fetch(uploadURL, {
              method: "POST",
              body: formData,
            });
          } else {
            alert("Failed to upload. No upload URL found.");
          }
        } catch (e) {
          // TODO: Error message
          console.error(e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const remove = async (value: any) => {
    console.log("remove", value);
    await deleteImage({
      variables: {
        id: itemId,
        forType: fieldProps.forType,
      },
    });
  };

  return (
    <div>
      <div className="mt-2">
        <ImageUpload
          imageIds={[value]}
          label={children}
          onRemoved={remove}
          multiple={false}
          required={required}
          onChange={update}
        />
      </div>
    </div>
  );
}

export function ReferenceInput({
  name,
  children,
  description,
  value,
  required,
  extraData,
  onChange,
  fieldProps,
}: {
  name: string;
  children: React.ReactNode;
  description?: string;
  value?: any;
  required?: boolean;
  extraData?: any;
  onChange: (field: string, value: any) => void;
  fieldProps?: any;
}) {
  const [getSearchResults, { data }] =
    fieldProps.useGetSearchResultsLazyQuery();

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState({
    id: value,
    name: fieldProps.parseDefaultName(extraData),
  });

  const [searchResults, setSearchResults] = useState<any>([]);

  useEffect(() => {
    if (data) {
      setSearchResults(fieldProps.parseSearchResult(data));
    }
  }, [data]);
  useEffect(() => {
    if (query.length < 3) {
      setSearchResults([]);
      return;
    }
    getSearchResults({
      variables: {
        query: `%${query}%`,
      },
    });
  }, [query]);
  useEffect(() => {
    console.log("selected", name, selected);
    onChange(name, selected.id);
  }, [selected]);

  return (
    <Combobox as="div" value={selected} onChange={setSelected}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        {children}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: any) => item?.name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {searchResults.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {searchResults.map((item: any) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {item.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

export function OptionsInput({
  name,
  description,
  value,
  children,
  required,
  options = [],
  nullable,
  onChange,
}: {
  name: string;
  description?: string;
  value?: any;
  required?: boolean;
  children: React.ReactNode;
  options: Option[] | undefined;
  nullable?: boolean;
  onChange: (field: string, value: any) => void;
}) {
  if (!options) {
    return null;
  }
  const allOptions = nullable
    ? [{ id: null, name: "Ingen" }, ...options]
    : options;
  const className =
    "grid grid-cols-1 gap-y-6 sm:gap-x-4" + GRID_COLS[options?.length - 1];

  const update = (value: any) => {
    console.log("update", value);
    onChange(name, value);
  };
  console.log(name, value);

  return (
    <Listbox value={value} onChange={(value) => update(value)}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {children}
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">
                {allOptions?.find((o) => o.id === value)?.name || ""}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {allOptions.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option.id}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export function OptionsInputOld({
  name,
  description,
  value,
  children,
  required,
  options = [],
  nullable,
  onChange,
}: {
  name: string;
  description?: string;
  value?: any;
  required?: boolean;
  children: React.ReactNode;
  options: Option[] | undefined;
  nullable?: boolean;
  onChange: (field: string, value: any) => void;
}) {
  if (!options) {
    return null;
  }
  const allOptions = nullable
    ? [{ id: null, name: "Ingen" }, ...options]
    : options;
  const className =
    "grid grid-cols-1 gap-y-6 sm:gap-x-4" + GRID_COLS[options?.length - 1];

  const update = (value: any) => {
    onChange(name, value);
  };

  return (
    <div>
      <RadioGroup value={value} onChange={(value) => update(value)}>
        <RadioGroup.Label className="block text-sm font-medium leading-6 text-gray-900">
          {children}
        </RadioGroup.Label>

        <div className={className}>
          {allOptions.map((item) => (
            <RadioGroup.Option
              key={item.id}
              value={item.id}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "border-sportgreen ring-2 ring-sportgreen" : "",
                  "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <div className="flex flex-1">
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {item.name}
                      </RadioGroup.Label>
                    </div>
                  </div>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? "invisible" : "",
                      "h-5 w-5 text-sportgreen"
                    )}
                    aria-hidden="true"
                  />
                  <div
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-sportgreen" : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-lg"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}

export function Field({
  children,
  colSpan,
  info,
}: {
  children: React.ReactNode;
  colSpan: number;
  info?: string | ((data: any) => string);
}) {
  const colSpans = [
    "",
    "sm:col-span-1",
    "sm:col-span-2",
    "sm:col-span-3",
    "sm:col-span-4",
    "sm:col-span-5",
    "sm:col-span-6",
  ];
  return (
    <div className={colSpans[colSpan]}>
      {children}
      {info && (
        <div className="text-sm bg-sportlightgreen text-sportdarkgreen p-4 rounded-xl">
          {info instanceof Function ? info({}) : info}
        </div>
      )}
    </div>
  );
}
