import { Link } from "react-router-dom";
import { BoltIcon } from "@heroicons/react/24/solid";

export type FieldType =
  | "string"
  | "number"
  | "boolean"
  | "date"
  | "link"
  | "currency";

export type FiledDescription = {
  name: string;
  type: FieldType;
  config?: any | undefined;
};

export type FieldDescriptionList = { [key: string]: FiledDescription };

export type DataTableProps = {
  tableName: string;
  description?: string | undefined;
  fields: FieldDescriptionList;
  data: any[];
};

export type FieldProps = {
  data: any;
  fieldId: string;
  field: FiledDescription;
};

export type FieldMap = { [key: string]: React.FC<FieldProps> };

function DataField({ data, fieldId, field }: FieldProps) {
  return <>{data[fieldId]}</>;
}

function CurrencyField({ data, fieldId, field }: FieldProps) {
  const formatedValue = Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(data[fieldId])
    .replace("kr", "")
    .trim();
  return <>{formatedValue}</>;
}

function LinkField({ data, fieldId, field }: FieldProps) {
  const { link, linkPostfix } = field.config;
  return (
    <Link
      className="text-sportgreen underline"
      to={`${link}${data[linkPostfix]}`}
    >
      {data[fieldId]}
    </Link>
  );
}

function DateField({ data, fieldId, field }: FieldProps) {
  const formatedDate = new Date(data[fieldId]).toLocaleDateString("nb-NO");
  return <>{formatedDate}</>;
}

function BooleanField({ data, fieldId, field }: FieldProps) {
  return (
    (data[fieldId] && <BoltIcon className="h-5 w-5 text-sportyellow" />) || null
  );
}

const fieldMap = {
  string: DataField,
  number: DataField,
  boolean: BooleanField,
  date: DateField,
  link: LinkField,
  currency: CurrencyField,
} as FieldMap;

const Field = ({ data, fieldId, field }: FieldProps) => {
  const Field = fieldMap[field.type] || DataField;
  return <Field data={data} fieldId={fieldId} field={field} />;
};

export default function DataTable({
  tableName,
  description,
  fields,
  data,
}: DataTableProps) {
  const fieldRows = Object.keys(fields);
  const csv = data.map((row) => {
    return Object.values(row).join(",");
  });

  const csvData = new Blob([csv.join("\n")], { type: "text/csv" });
  return (
    <div className="mt-4 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {tableName}
          </h1>
          {description && (
            <p className="mt-2 text-sm text-gray-700">{description}</p>
          )}
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <a
            href={URL.createObjectURL(csvData)}
            download="data.csv"
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Export
          </a>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {fieldRows.map((field) => (
                    <th
                      key={field}
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {fields[field].name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.map((row) => (
                  <tr key={row.id}>
                    {fieldRows.map((field) => (
                      <td
                        key={field}
                        className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                      >
                        <Field
                          data={row}
                          fieldId={field}
                          field={fields[field]}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
