import { gql, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";

import config from "../../config";
import CommandPallet from "../atom/CommandPallet";
const SPORT = [config.org];
const GETUSERORG = gql`
  mutation GetUserOrg {
    getUser(input: {}) {
      user {
        id
      }
    }
    getOrg(input: {}) {
      org {
        id
      }
    }
  }
`;

export default function Header({ user, setMobileMenuOpen }) {
  const [getUserOrg] = useMutation(GETUSERORG);
  const [open, setOpen] = useState(false);
  const { logout } = useAuth0();
  //const s = getUserOrg()
  useEffect(() => {
    getUserOrg();
  }, []);
  const userIsSport = SPORT.includes(user.org_id);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.metaKey && event.key === "k") {
        event.preventDefault();
        setOpen(true);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  // const [loading, setLoading] = useState(false)
  // const  handleGetUserOrg = () => {
  //   if (loading) return
  //   setLoading(true)
  //   getUserOrg().then(({data}) => {

  //     //setLoading(false);
  //   })
  // }
  // handleGetUserOrg()

  return (
    <>
      {userIsSport && <CommandPallet open={open} setOpen={setOpen} />}
      {/* Content area */}
      <header className="w-full">
        <div className="relative z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm flex">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 flex justify-between px-4 sm:px-6">
            <div className="flex-1 flex">
              {userIsSport && (
                <div className="w-full flex justify-end md:ml-0 mt-2">
                  <button
                    type="button"
                    className="flex items-center w-12 sm:w-72 text-left space-x-3 px-4 h-12 bg-white sm:ring-1 ring-slate-900/10 hover:ring-slate-300 focus:outline-none focus:ring-2 focus:ring-sportgreen shadow-sm rounded-lg text-slate-400"
                    onClick={() => setOpen(true)}
                  >
                    <MagnifyingGlassIcon
                      className="flex-shrink-0 h-5 w-5"
                      aria-hidden="true"
                    />

                    <span className="flex-auto hidden sm:block">
                      Quick search...
                    </span>
                    <kbd className="font-sans font-semibold dark:text-slate-500 hidden sm:block">
                      <abbr
                        title="Command"
                        className="no-underline text-slate-300 dark:text-slate-500"
                      >
                        ⌘
                      </abbr>{" "}
                      K
                    </kbd>
                  </button>
                </div>
              )}
              {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search all files
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                        <MagnifyingGlassIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        name="search-field"
                        id="search-field"
                        className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </form> */}
            </div>
            <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
              {/* Profile dropdown */}
              <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src={user.picture}
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <button
                      onClick={() => logout()}
                      className="block px-4 py-2 text-sm text-gray-700"
                    >
                      Logout
                    </button>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
