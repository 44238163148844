import { OrderItem, Order as OrderType } from "@app/graphql/types";
import { FormFieldComponentProps } from "@app/types";
import { createImageUrl } from "../../../utils/dom";

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("no-NO", {
    style: "currency",
    currency: "NOK",
  }).format(amount);
};

function OrderItemSummary({ orderItem }: { orderItem: OrderItem }) {
  const inventory = orderItem.inventory;
  const product = orderItem?.product;
  console.log(orderItem);
  const image = inventory
    ? inventory?.inventoryImages?.nodes[0]?.id
    : product?.image;

  if (inventory || product) {
    return (
      <li className="flex space-x-6 py-6">
        {image && (
          <img
            src={createImageUrl(image)}
            alt={orderItem.name || ""}
            className="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center"
          />
        )}
        <div className="flex-auto space-y-1">
          <h3 className="text-gray-900">{orderItem.name}</h3>
        </div>
        <p className="flex-none font-medium text-gray-900">
          {formatCurrency(orderItem.price / 100)}
        </p>
      </li>
    );
  } else {
    return null;
  }
}

export function OrderSummary({ id, name, data }: FormFieldComponentProps) {
  const order = data as OrderType;
  console.log(order);
  return (
    <div className="border bg-sportlightgrey p-10 rounded-lg">
      <h2 className="font-medium text-xl">{name}</h2>
      <ul
        role="list"
        className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500"
      >
        {order?.orderItems?.nodes.map((orderItem) => (
          <OrderItemSummary key={orderItem.id} orderItem={orderItem} />
        ))}
      </ul>

      <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
        <div className="flex justify-between">
          <dt>Subtotal</dt>
          <dd className="text-gray-900">
            {formatCurrency(Number.parseInt(order.total) / 100)}
          </dd>
        </div>
      </dl>
      {order.shippingCost && (
        <div className="flex justify-between">
          <dt>Frakt</dt>
          <dd className="text-gray-900">
            {formatCurrency(Number.parseInt(order.shippingCost) / 100)}
          </dd>
        </div>
      )}
      {order.discountAmount && (
        <div className="flex justify-between">
          <dt>Rabatt {order.discountCode}</dt>
          <dd className="text-gray-900">
            -{formatCurrency(Number.parseInt(order.discountAmount) / 100)}
          </dd>
        </div>
      )}
      {order.totalDue && (
        <div className="flex justify-between">
          <dt className="font-bold text-black">Total </dt>
          <dd className="ont-bold text-black">
            {formatCurrency(Number.parseInt(order.totalDue) / 100)}
          </dd>
        </div>
      )}
    </div>
  );
}
