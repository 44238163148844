export default function SelectedRows({
  selected,
  setSelected,
  selection,
  totalCount,
  pageSize,
}) {
  if (totalCount <= pageSize || selected.length < pageSize) {
    return null
  }

  return (
    <div className="w-full px-4 py-2 mb-6 rounded bg-gray-200 text-sm text-center">
      {selection === 'current-page' ? (
        <>
          All <span className="font-medium">{selected.length}</span> rows on
          this page are selected.{' '}
          <button
            type="button"
            value="all"
            className="text-indigo-600"
            onClick={setSelected}
          >
            Click to select all{' '}
            <span className="font-medium">{totalCount}</span> rows
          </button>{' '}
          that match this search.
        </>
      ) : (
        <>
          All <span className="font-medium">{totalCount}</span> rows that match
          this search are selected.{' '}
          <button
            type="button"
            value="current-page"
            className="text-indigo-600"
            onClick={setSelected}
          >
            Click to select only the{' '}
            <span className="font-medium">{selected.length}</span> rows
          </button>{' '}
          on this page.
        </>
      )}
    </div>
  )
}
