import { useState } from "react";
import { Button, FormButton } from "../../../components/common/Form";

import {
  useGenerateInventoryVideoMutation,
  useResetInventoryMutation,
  useSyncInventoryToFinnMutation,
  useUpdateInventoryBicycleMutation,
  useUpdateInventoryMutation,
  useUpdateInventorySkiMutation,
} from "@app/graphql/pim";

import { VideoCameraIcon } from "@heroicons/react/24/solid";

import LoadingInline from "../../../components/atom/LoadingInline";

type SaveButtonsProps = {
  id: string;
  data: any;
  callback: () => void;
  skiData?: any | undefined;
  bicycleData?: any | undefined;
};

export function SaveButtons({
  id,
  data,
  callback,
  skiData,
  bicycleData,
}: SaveButtonsProps) {
  const [update] = useUpdateInventoryMutation();
  const [updateSki] = useUpdateInventorySkiMutation();
  const [updateBicycle] = useUpdateInventoryBicycleMutation();

  const [loading, setLoading] = useState(false);
  const handleUpdate = async () => {
    if (loading) return;

    try {
      setLoading(true);

      if (skiData) {
        await updateSki({
          variables: {
            patch: {
              ...skiData,
              length: parseInt(skiData["length"]),
            },
            id: id,
          },
        });
      }
      if (bicycleData) {
        await updateBicycle({
          variables: {
            patch: {
              ...bicycleData,
            },
            id: id,
          },
        });
      }

      await update({
        variables: {
          patch: {
            ...data,
            price: parseInt(data["price"]),
            score: parseInt(data["score"]),
          },
          id: id,
        },
      });
      callback();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return <FormButton type="save" onClick={handleUpdate} />;
}

type ResetButtonProps = {
  id: string;
  callback: () => void;
};

export function ResetButton({ id, callback }: ResetButtonProps) {
  const [reset] = useResetInventoryMutation();
  const [loading, setLoading] = useState(false);
  const handleReset = async () => {
    if (loading) return;
    if (!window.confirm("Are you sure you want to reset?")) return;

    try {
      setLoading(true);

      await reset({
        variables: {
          inventoryId: id,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return <FormButton type="reset" onClick={handleReset} />;
}

export function GenerateVideoButton({ inventoryId }: { inventoryId: string }) {
  const [generateVideo] = useGenerateInventoryVideoMutation({
    variables: {
      inventoryId,
    },
  });
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await generateVideo();
    setLoading(false);
  };

  return (
    <div className="flex space-x-2 py-4 justify-start items-center">
      {loading ? (
        <LoadingInline />
      ) : (
        <Button onClick={() => handleClick()}>
          <VideoCameraIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          Generate Video
        </Button>
      )}
    </div>
  );
}

export function SyncToFinnButton({ inventoryId }: { inventoryId: string }) {
  const [syncToFinn] = useSyncInventoryToFinnMutation({
    variables: {
      inventoryId,
    },
  });
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await syncToFinn();
    setLoading(false);
  };

  return (
    <div className="flex space-x-2 py-4 justify-start items-center">
      {loading ? (
        <LoadingInline />
      ) : (
        <Button onClick={() => handleClick()}>Sync til finn.no</Button>
      )}
    </div>
  );
}
