import { gql, useQuery } from "@apollo/client";

const GET_BRANDS = gql`
  query BrandsQuery {
    inStockBrands {
      nodes {
        name
        id
      }
    }
  }
`;

const GET_YEARS = gql`
  query GetYears {
    inStockBicycleYears {
      nodes
    }
  }
`;

const GET_SIZES = gql`
  query GetSizes {
    bicycleSizes {
      nodes {
        id
        name
      }
    }
  }
`;

const useTheQuery = (query, variables, skip = false) =>
  useQuery(query, {
    variables,
    skip,
    nextFetchPolicy: "cache-and-network",
    context: { clientName: "public" },
  });

export function useGetBrands() {
  const { loading, data } = useTheQuery(GET_BRANDS);
  const brands = data?.inStockBrands?.nodes || [];

  return { brands, loading };
}

export function useGetYears() {
  const { loading, data } = useTheQuery(GET_YEARS);
  const result = data?.inStockBicycleYears?.nodes || [];
  const years = result.map((year) => ({
    id: year,
    name: year,
  }));

  return { years, loading };
}

export function useGetSizes() {
  const { loading, data } = useTheQuery(GET_SIZES);
  const ORDER = ["one size", "xxs", "xs", "s", "m", "l", "xl", "2xl", "xxl"];
  const result = data?.bicycleSizes?.nodes || [];
  const sizes = result.map(({ id, name }) => ({ id, name }));

  if (sizes) {
    sizes.sort((a, b) => {
      a = a.id.toLowerCase();
      b = b.id.toLowerCase();
      let nra = parseInt(a);
      let nrb = parseInt(b);

      if (ORDER.indexOf(a) !== -1) nra = NaN;
      if (ORDER.indexOf(b) !== -1) nrb = NaN;

      if (nrb === 0) return 1;
      if ((nra && !nrb) || nra === 0) return -1;
      if (!nra && nrb) return 1;
      if (nra && nrb) {
        if (nra === nrb) {
          return a
            .substr(("" + nra).length)
            .localeCompare(a.substr(("" + nra).length));
        } else {
          return nra - nrb;
        }
      } else {
        return ORDER.indexOf(a) - ORDER.indexOf(b);
      }
    });
  }

  return { sizes, loading };
}
