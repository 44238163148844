import {
  useBrandFamiliesQuery,
  useBrandFamilyQuery,
  useCreateBrandFamilyMutation,
  useReferenceBrandsLazyQuery,
  useUpdateBrandFamilyMutation,
} from "@app/graphql/pim";
import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";
import model from "./model";

export const addInitialValues = {
  name: null,
  brandId: null,
};

export const addForm: Form = [
  {
    id: "name",
    name: "Name",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "brandId",
    name: "Brand",
    type: FieldType.reference,
    required: true,
    zFieldType: z.string(),
    fieldProps: {
      useGetSearchResultsLazyQuery: useReferenceBrandsLazyQuery,
      parseSearchResult: (data: any) => data?.brands?.nodes || [],
      parseDefaultName: (data: any) => data?.brand?.name || "",
    },
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "id",
    name: "ID",
  },

  {
    id: "name",
    name: "Name",
  },
  {
    id: "brand",
    name: "Brand",
    parseFormData: (data) => data?.name,
  },
];

const filters: ItemFilter[] = [
  {
    name: "name",
    type: "text",
    subtype: "like",
  },
];

const brandFamily: ItemSchema = {
  id: "brandFamily",
  name: "BrandFamily",
  plural: "BrandFamilies",
  idIsNumber: true,
  listColumns,
  addForm,
  filters,
  editForm,
  addInitialValues,
  editInitialValues,
  parentId: "brandFamilyId",
  parentObjectName: "brandFamily",
  children: [model],

  useItemsQuery: useBrandFamiliesQuery,
  useItemQuery: useBrandFamilyQuery,
  useCreateItemMutation: useCreateBrandFamilyMutation,
  useUpdateItemMutation: useUpdateBrandFamilyMutation,
  parseItem: (data) => data?.brandFamily || {},
  parseItems: (data) =>
    ({
      totalCount: data?.brandFamilies?.totalCount,
      items: data?.brandFamilies?.nodes,
    } || []),
  createInputFromValues: (values) => ({
    brandFamily: values,
  }),
};

export default brandFamily;
