import { BicycleType, InventoryType, ObjectType, SkiType } from "@app/graphql";
import {
  useAddOnProductQuery,
  useAddOnProductsQuery,
  useCreateAddOnProductMutation,
  useProductsLazyQuery,
  useUpdateAddOnProductMutation,
} from "@app/graphql/pim";
import { FieldType, Form, ItemSchema, ListColumn } from "@app/types";
import { z } from "zod";
import { getOptionsFromEnum } from "../utils/schema";

export const addInitialValues = {
  productId: null,
  forInventoryType: null,
  forObjectType: null,
  forBicycleType: null,
  forSkiType: null,
  published: false,
};

export const addForm: Form = [
  {
    id: "productId",
    name: "Produkt",
    type: FieldType.reference,
    required: true,
    zFieldType: z.string(),
    fieldProps: {
      useGetSearchResultsLazyQuery: useProductsLazyQuery,
      parseSearchResult: (data: any) => data?.products?.nodes || [],
      parseDefaultName: (data: any) => data?.product?.name || "",
    },
  },
  {
    id: "forInventoryType",
    name: "For Lagervaretype",
    type: FieldType.select,
    required: true,
    nullable: true,
    values: getOptionsFromEnum(InventoryType),
    zFieldType: z.nativeEnum(InventoryType),
  },
  {
    id: "forObjectType",
    name: "For Objekttype",
    type: FieldType.select,
    required: true,
    nullable: true,
    values: getOptionsFromEnum(ObjectType),
    zFieldType: z.nativeEnum(ObjectType),
  },
  {
    id: "forBicycleType",
    name: "For Sykkeltype",
    type: FieldType.select,
    required: true,
    nullable: true,
    values: getOptionsFromEnum(BicycleType),
    zFieldType: z.nativeEnum(BicycleType),
  },
  {
    id: "forSkiType",
    name: "For Skitype",
    type: FieldType.select,
    required: true,
    nullable: true,
    values: getOptionsFromEnum(SkiType),
    zFieldType: z.nativeEnum(SkiType),
  },
];

export const editForm: Form = [
  ...addForm,

  {
    id: "published",
    name: "Publisert",
    type: FieldType.boolean,
    required: false,
    zFieldType: z.boolean().optional(),
  },
];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "productId",
    name: "Produkt",
    parseExtraData: (data) => data?.product?.name || "",
  },
  {
    id: "published",
    name: "Publisert",
    parseExtraData: (data) => (data.published ? "Ja" : "Nei"),
  },
  {
    id: "forInventoryType",
    name: "For Lagervaretype",
  },
  {
    id: "forObjectType",
    name: "For Objekttype",
  },
  {
    id: "forBicycleType",
    name: "For Sykkeltype",
  },
  {
    id: "forSkiType",
    name: "For Skitype",
  },
];

const addOnProduct: ItemSchema = {
  id: "addOnProduct",
  name: "Tilleggsprodukt",
  plural: "Tilleggsprodukter",
  listColumns,
  addForm,
  editForm,
  addInitialValues,
  editInitialValues,

  useItemsQuery: useAddOnProductsQuery,
  useItemQuery: useAddOnProductQuery,
  useCreateItemMutation: useCreateAddOnProductMutation,
  useUpdateItemMutation: useUpdateAddOnProductMutation,
  parseItem: (data) => data?.addOnProduct || {},
  parseItems: (data) =>
    ({
      totalCount: data?.addOnProducts?.totalCount,
      items: data?.addOnProducts?.nodes || [],
    } || []),
  createInputFromValues: (values) => ({
    addOnProduct: values,
  }),
};

export default addOnProduct;
