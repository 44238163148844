export default {
  org: process.env.REACT_APP_SPORTIENDA_ORG,
  admin_orgs: [ process.env.REACT_APP_SPORTIENDA_ORG, process.env.REACT_APP_BIRK_ORG ],
  features: {
    publicCalculator: !!process.env.REACT_APP_ENABLE_PUBLIC_CALCULATOR,
  },

  storage: {
    images: 'https://storage.googleapis.com/sportienda-bicycle-images',
  },
};
