import { CheckIcon } from "@heroicons/react/24/solid";

export default function Steps({ current, steps }) {
  return (
    <nav aria-label="Progress">
      <ol className="divide-y divide-gray-300 border-y border-gray-300 bg-white md:flex md:divide-y-0 mb-4">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {stepIdx < current ? (
              <div className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-sportgreen">
                    <CheckIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </div>
            ) : stepIdx === current ? (
              <div
                className="flex items-center px-6 py-4 text-sm font-medium"
                aria-current="step"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-sportgreen">
                  <span className="text-sportgreen">{step.id}</span>
                </span>
                <span className="ml-4 text-base font-bold text-sportgreen">
                  {step.name}
                </span>
              </div>
            ) : (
              <div className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 ">
                    <span className="text-gray-500">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 ">
                    {step.name}
                  </span>
                </span>
              </div>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div
                  className="absolute top-0 right-0 hidden h-full w-5 md:block"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}

export function CompactSteps({ current, steps }) {
  return (
    <nav
      className="flex items-center justify-center border-t pt-5 border-b pb-5 border-sportlightgrey"
      aria-label="Progress"
    >
      <p className="text-sm font-medium">
        Steg {current + 1} av {steps.length}
      </p>
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {steps.map((step, stepIdx) => (
          <li key={step.id}>
            {stepIdx < current ? (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-sportgreen hover:bg-sportblack"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            ) : stepIdx === current ? (
              <a
                href={step.href}
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span
                  className="absolute flex h-4 w-4 p-px z-4"
                  aria-hidden="true"
                >
                  <span className="h-full w-full rounded-full bg-sportgreen blur-sm relative z-4" />
                </span>
                <span
                  className="relative block h-2.5 w-2.5 rounded-full bg-sportgreen z-10"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </a>
            ) : (
              <a
                href={step.href}
                className="block h-2.5 w-2.5 rounded-full bg-sportgrey hover:bg-sportgreyweb"
              >
                <span className="sr-only">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
