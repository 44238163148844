export async function authedFetch(
  url: string,
  options: RequestInit,
  getAccessTokenSilently: () => Promise<string>
): Promise<Response> {
  const token = await getAccessTokenSilently();

  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  });
}
