import { gql, useMutation } from "@apollo/client";
import { Dialog, Listbox, Menu, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  ChevronUpDownIcon,
  DocumentIcon,
  EllipsisVerticalIcon,
  Square2StackIcon,
  TagIcon,
} from "@heroicons/react/24/solid";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingIcon from "../../../components/atom/LoadingIcon";
import { classNames } from "../../../utils/dom";
import { useNotification } from "../../../utils/notify";

const BULK_UPDATE = gql`
  mutation BulkUpdateBicyclePriceQueries(
    $input: BulkUpdateBicyclePriceQueriesInput!
  ) {
    bulkUpdateBicyclePriceQueries(input: $input) {
      integer
    }
  }
`;

export default function BulkActions({ selected, data }) {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [modalField, setModalField] = useState(false);
  const [bulkUpdate, { loading }] = useMutation(BULK_UPDATE);

  const disabled = selected.length === 0 || loading;

  const close = () => setModalField(false);

  const actions = [
    {
      name: t("Bulk draft"),
      Icon: DocumentIcon,
      field: "published",
      value: "false",
    },
    {
      name: t("Bulk publish"),
      Icon: CheckCircleIcon,
      field: "published",
      value: "true",
    },
    { name: t("Bulk tag ..."), Icon: TagIcon, field: "tag", value: undefined },
    {
      name: t("Bulk category ..."),
      Icon: Square2StackIcon,
      field: "category",
      value: undefined,
    },
  ];

  const update = async (field, value, action) => {
    setModalField(false);
    const refetchQueries = ["GetBicyclePriceQueries"];
    const recipeIds = selected;
    const variables = { input: { field, value, action, recipeIds } };
    const { data } = await bulkUpdate({ variables, refetchQueries });
    const rowCount = data?.bulkUpdateBicyclePriceQueries?.integer;
    const message = `${rowCount} ${t("rows were updated")}`;
    showNotification(message);
  };

  const handleClick = (field, value) => {
    if (["category", "tag"].includes(field)) {
      setModalField(field);
    } else {
      update(field, value, "UPDATE");
    }
  };

  return (
    <>
      <Menu as="div" className="relative z-20 inline-block text-left">
        <div>
          <Menu.Button
            className="inline-flex justify-center w-42 rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            disabled={disabled}
          >
            {loading ? (
              <LoadingIcon className="h-5 w-5" />
            ) : (
              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              {actions.map(({ name, Icon, field, value }, key) => (
                <Menu.Item key={key}>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "group w-full flex items-center px-4 py-2 text-sm font-medium whitespace-nowrap"
                      )}
                      onClick={() => handleClick(field, value)}
                    >
                      <Icon
                        className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      {modalField && (
        <Modal close={close} data={data} field={modalField} update={update} />
      )}
    </>
  );
}

function Modal({ close, data, field, update }) {
  const [action, setAction] = useState();
  const [value, setValue] = useState();
  const options =
    field === "category" ? data?.categories?.nodes : data?.tags?.nodes;

  const submit = (e) => {
    e.preventDefault();
    update(field, value, action);
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-20 inset-0 overflow-y-auto"
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              name="bulk-update"
              method="post"
              className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              onSubmit={submit}
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={close}
                >
                  <span className="sr-only">Close</span>
                  <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationCircleIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 flex-1 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Bulk update
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm leading-5 text-gray-500">
                      {`Select the action you want to perform and the ${field} to apply for the selected pricequeries.`}
                    </p>
                    <div className="mt-4">
                      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                        <div className="flex items-center">
                          <input
                            id="action-add"
                            name="action"
                            type="radio"
                            checked={action === "INSERT"}
                            onChange={() => setAction("INSERT")}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            required
                          />
                          <label
                            htmlFor="action-add"
                            className="ml-2 block text-sm font-medium text-gray-700"
                          >
                            Add
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id="action-remove"
                            name="action"
                            type="radio"
                            checked={action === "DELETE"}
                            onChange={() => setAction("DELETE")}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            required
                          />
                          <label
                            htmlFor="action-remove"
                            className="ml-2 block text-sm font-medium text-gray-700"
                          >
                            Remove
                          </label>
                        </div>
                      </div>
                    </div>
                    <Select
                      options={options}
                      name={field}
                      value={value}
                      setValue={setValue}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-5 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Update
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={close}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Select({ options = [], name, value, setValue }) {
  const { t } = useTranslation();
  const selected = options.find((option) => String(option.id) === value);
  const placeholder = t(`- Select ${name} -`);

  return (
    <Listbox
      value={selected}
      onChange={(option) => setValue(String(option.id))}
    >
      {({ open }) => (
        <>
          <div className="relative mt-4">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">
                {selected?.name ?? placeholder}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-20 mt-1 w-full min-w-fit bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map((option, key) => (
                  <Fragment key={key}>
                    <Listbox.Option
                      value={option}
                      className="max-h-60 hover:bg-indigo-50"
                    >
                      <label className="relative flex items-start py-2 px-3">
                        <div className="flex items-center h-5">
                          <input
                            name={name}
                            type="radio"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            value={option.id}
                            defaultChecked={selected?.id === option.id}
                          />
                        </div>
                        <div className="ml-2.5 text-sm">
                          <span
                            className={classNames(
                              selected?.id === option.id
                                ? "font-semibold"
                                : "font-normal",
                              "block truncate text-gray-700"
                            )}
                          >
                            {option.name}
                          </span>
                        </div>
                      </label>
                    </Listbox.Option>
                    {option.id === "all" && (
                      <div className="my-1 border-b border-gray-200" />
                    )}
                  </Fragment>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
