export const classNames = (...names) => names.flat().filter(Boolean).join(" ");

export const createImageUrl = (id, size) =>
  `https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/${id}/${size || "public"}`;

export const getTranslation = (dictionary, key) => {
  console.log("dictionary", key);
  if (key.includes(".")) {
    const [first, ...rest] = key.split(".");

    return getTranslation(dictionary?.[first], rest.join("."));
  }
  return dictionary?.[key] || key;
};
