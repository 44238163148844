import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";

import { useBicycleCheckStatusesQuery } from "@app/graphql/pim";
import LoadingInline from "./LoadingInline";

type Props = {
  bicyclePriceQueryId: string;
};

export default function CheckStolenBike({ bicyclePriceQueryId }: Props) {
  //network only
  const { data, error, loading } = useBicycleCheckStatusesQuery({
    variables: {
      bicyclePriceQueryId: bicyclePriceQueryId,
    },
    fetchPolicy: "network-only",
  });
  if (loading) return <LoadingInline />;
  if (error) return <p>Error :( {error.message}</p>;
  return (
    <div className="mt-8 flex flex-col col-span-6">
      <div className="py-5">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Sykkelregister sjekk
        </h3>
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Register
                  </th>

                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Rammenummer
                  </th>
                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Mottatt
                  </th>
                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>

                  <th className="px-3 py-3 text-left text-sm font-semibold text-gray-900">
                    Rammenummer?
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.bicycleCheckStatuses?.nodes.map((obj, key) => {
                  return (
                    <tr key={key}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {obj.registry}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-sportblack font-bold">
                        <a href={obj.url || "#"} target="_blank">
                          {obj.status}
                        </a>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {obj.frameNumber}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {obj.frameNumberSource}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {obj.frameNumber === obj.frameNumberSource ? (
                          <CheckCircleIcon
                            className="h-5 w-5 text-sportgreen"
                            aria-hidden="true"
                          />
                        ) : (
                          <XCircleIcon
                            className="h-5 w-5 text-sportyellow"
                            aria-hidden="true"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
