import { useQuery, gql } from '@apollo/client';

const enumQuery = gql`
  query  GetEnum($enumName: String!){
    __type(name: $enumName) {
      enumValues {
        name
      }
    }
  }
`


export default function useEnum(enumName) {
    
    const {data} = useQuery(enumQuery, {variables: {enumName: enumName}})
    return  data?.__type?.enumValues.map((row) => ({'id': row.name, 'name': row.name})) ?? []
  }
  