import type { Order as OrderType } from "@app/graphql/pim";
import { FormFieldComponentProps } from "@app/types";
import InventoryHeader from "../../../components/atom/InventoryHeader";

export default function OrderHeader({
  id,
  name,
  data,
}: FormFieldComponentProps) {
  const order = data as OrderType;

  const inventory = order?.orderItems?.nodes[0]?.inventory;
  const bicycleIntakeForm = inventory?.bicycleIntakeForm;
  const bicyclePriceQuery = bicycleIntakeForm?.bicyclePriceQuery;

  return (
    <InventoryHeader
      name={inventory?.name || undefined}
      bicyclePriceQuery={bicyclePriceQuery || undefined}
      bicycleIntakeForm={bicycleIntakeForm || undefined}
      inventory={inventory || undefined}
      order={order}
    />
  );
}
