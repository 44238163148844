import {
  InventoryType,
  ObjectType,
  useCreateInventoryMutation,
} from "@app/graphql/pim";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type AddInventoryProps = {
  type: string;
  closeCallback: () => void;
};

export default function AddInventory({
  type,
  closeCallback,
}: AddInventoryProps) {
  const [name, setName] = useState<string>("");

  const [createInventory] = useCreateInventoryMutation();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const inventory = await createInventory({
      variables: {
        name: name,
        objectType: ObjectType.Ski,
        type: InventoryType.Outlet,
      },
    });
    if (inventory.data?.createInventory?.inventory) {
      navigate(`${inventory.data.createInventory.inventory.id}`);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="sm:col-span-4">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Tittel
        </label>
        <div className="mt-2">
          <input
            id="name"
            name="name"
            type="string"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="button"
          onClick={closeCallback}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
