import BikeIcon from "../../media/images/bike.svg";
import { classNames } from "../../utils/dom";

/* eslint-disable react/prop-types */

export const createImageUrl = (id, defaultImage) =>
  id
    ? `https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/${id}/public`
    : defaultImage;

export default function BicycleImage({ alt, image, defaultSrc, className }) {
  const id = image?.id;
  const defaulImage = defaultSrc || BikeIcon;

  const classes = classNames(
    "h-full w-full object-cover object-center",
    className
  );

  const src = createImageUrl(id, defaulImage);

  return <img src={src} alt={alt || ""} className={classes} />;
}
