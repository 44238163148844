import {
  TypeOfStatus,
  useBrandQuery,
  useCreateBrandMutation,
  usePimBrandsQuery,
  useUpdateBrandMutation,
} from "@app/graphql/pim";
import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";

import brandFamily from "./brand_family";

import { getOptionsFromEnum } from "../utils/schema";
export const addInitialValues = {
  name: null,
  brandBonus: 1.0,
  hasSkis: false,
  status: "DRAFT",
};

export const addForm: Form = [
  {
    id: "name",
    name: "Name",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "status",
    name: "Status",
    type: FieldType.select,
    values: getOptionsFromEnum(TypeOfStatus),
    zFieldType: z.nativeEnum(TypeOfStatus),
    required: true,
  },
  {
    id: "brandBonus",
    name: "Brand Bonus",
    type: FieldType.number,
    required: true,
    zFieldType: z.number(),
  },
  {
    id: "hasSkis",
    name: "Has Skis",
    type: FieldType.boolean,
    required: true,
    zFieldType: z.boolean(),
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "id",
    name: "ID",
  },

  {
    id: "name",
    name: "Name",
  },

  {
    id: "status",
    name: "Status",
  },
  {
    id: "brandBonus",
    name: "Brand Bonus",
  },
  {
    id: "hasSkis",
    name: "Has Skis",
    parseFormData: (data) => (data ? "Yes" : "No"),
  },
];

const filters: ItemFilter[] = [
  {
    name: "name",
    type: "text",
    subtype: "like",
  },
  {
    name: "status",
    type: "enum",
    enum: TypeOfStatus,
  },
];

const brand: ItemSchema = {
  id: "brand",
  name: "Brand",
  plural: "Brands",
  idIsNumber: true,
  listColumns,
  addForm,
  filters,
  editForm,
  addInitialValues,
  editInitialValues,
  children: [brandFamily],
  parentId: "brandId",
  parentObjectName: "brand",
  useItemsQuery: usePimBrandsQuery,
  useItemQuery: useBrandQuery,
  useCreateItemMutation: useCreateBrandMutation,
  useUpdateItemMutation: useUpdateBrandMutation,
  parseItem: (data) => data?.brand || {},
  parseItems: (data) =>
    ({
      totalCount: data?.brands?.totalCount,
      items: data?.brands?.nodes,
    } || []),
  createInputFromValues: (values) => ({
    brand: values,
  }),
};

export default brand;
