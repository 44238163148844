import { useOutletQuery } from "@app/graphql/pim";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddInventory from "../components/atom/AddInventory";
import Loading from "../components/atom/Loading";
import BicycleForm from "../components/calculator/Form";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import BicycleImage from "../components/atom/BikeImage";
import { classNames } from "../utils/dom";
import PriceQueryList from "./PriceQueries/PriceQueryList";
type Tab = {
  name: string;
  description: string;
  href: string;
  iconColor: string;

  icon: any;
  iconStyle?: string | null | undefined;
  widget?: any;
};

export default function OutletDashboard() {
  const [activeTab, setActiveTab] = useState<Tab | null>(null);
  const closeCallback = () => {
    setActiveTab(null);
  };

  const tabs = [
    {
      name: "Innbytte",
      description:
        "Like a conference all about you that no one will care about.",
      href: "#",
      iconColor: "bg-sportgreen",
      icon: BicycleImage,
      iconStyle: " invert p-1 text-white",
      widget: <BicycleForm type={"tradein"} />,
    },
    {
      name: "Outlet – sykkel",
      description: "I think the kids call these memes these days.",
      href: "#",
      iconColor: "bg-sportblack",
      icon: BicycleImage,
      iconStyle: " invert p-1 text-white",
      widget: <BicycleForm type={"outlet"} />,
    },
    {
      name: "Outlet – ski",
      description: "I think the kids call these memes these days.",
      href: "#",
      iconColor: "bg-sportgrey text-black",
      iconStyle: " text-sportblack",

      icon: PlusCircleIcon,
      widget: <AddInventory type={"ski"} closeCallback={closeCallback} />,
    },
    // {
    //   name: "Demosykkel",
    //   description:
    //     "Something really expensive that will ultimately get cancelled.",
    //   href: "#",
    //   iconColor: "bg-sportred",
    //   icon: BicycleImage,
    //   iconStyle: " invert p-1 text-white",
    //   widget: <BicycleForm type={"tradein"} />,
    // },
  ] as Tab[];
  return (
    <>
      <div className="space-y-4 rounded-lg bg-white px-4 py-6 m-4  shadow-sportbox">
        <div className="relative">
          {!activeTab ? (
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Lag ny
              </h3>

              <ul
                role="list"
                className="mt-6 divide-y divide-gray-200 border-b border-t border-gray-200"
              >
                {tabs.map((tab, tabIdx) => (
                  <li key={tabIdx}>
                    <div className="group relative flex tabs-start space-x-3 py-4">
                      <div className="flex-shrink-0">
                        <span
                          className={classNames(
                            tab.iconColor,
                            "inline-flex h-10 w-10 tabs-center rounded-lg justify-center items-center"
                          )}
                        >
                          <tab.icon
                            className={classNames(tab.iconStyle, "h-6 w-6 ")}
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div className="text-sm font-medium text-gray-900">
                          <button onClick={() => setActiveTab(tab)}>
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            {tab.name}
                          </button>
                        </div>
                        <p className="text-sm text-gray-500">
                          {tab.description}
                        </p>
                      </div>
                      <div className="flex-shrink-0 self-center">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div>
              <div className="text-base font-semibold leading-6 text-gray-900 flex items-center">
                <button onClick={() => setActiveTab(null)}>
                  <ChevronLeftIcon className="h-5 w-5 text-gray-400 group-hover:text-gray-500    mr-2" />
                </button>
                Ny {activeTab.name}
              </div>
              <div className="mt-6">{activeTab.widget}</div>
            </div>
          )}
        </div>
      </div>
      <PriceQueryList type={"outlet"} />
    </>
  );
}

export function OutletForm() {
  const { id } = useParams();
  const { data, loading } = useOutletQuery({ variables: { id: id! } });
  if (loading) return <Loading />;

  const inventoryId =
    data?.bicyclePriceQuery?.bicycleIntakeForms?.nodes[0]?.inventories?.nodes[0]
      ?.id;

  return (
    <p className="text-center pt-5">
      <Link className="underline text-sportgreen" to={`../../inventory/${id}`}>
        Salgsplakat
      </Link>
    </p>
  );
}
