import { Suspense, lazy, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider } from "@apollo/client/react";
import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import DevInfo from "./components/atom/DevInfo";
import Loading from "./components/atom/Loading";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar";
import Dashboard from "./pages/Dashboard";
import OutletPage, { OutletForm } from "./pages/OutletPage";
import PriceQueryDetails from "./pages/PriceQueries/PriceQueryDetails";
import PriceQueryList from "./pages/PriceQueries/PriceQueryList";
import Protocol from "./pages/Protocol";
import { TradeInWrapper } from "./pages/Sell/SellForm";
import StatsPurchase from "./pages/StatsPurchase";
import TradeInPage from "./pages/TradeInPage";
import CMS from "./pages/cms/Cms";
import { ItemEdit } from "./pages/cms/Item";
import { ItemList } from "./pages/cms/List";
import IntakeDetails from "./pages/intake/IntakeDetails";
import IntakeList from "./pages/intake/IntakeLists";
import InventoryDetails from "./pages/inventory/InventoryDetails";
import InventoryList from "./pages/inventory/InventoryList";
import ValueByYearList from "./pages/valueByYear/valueByYearList";
import { forms } from "./schemata";

const NotFound = lazy(() => import("./components/layout/notfound"));

// import Breadcrumbs from './components/atom/Breadcrumbs'

function TrelloPage() {
  return (
    <div className="w-full h-full">
      <a
        href="https://trello.com/b/NxvD64ZU/inn-og-utleveringer"
        target="_blank"
        rel="noreferrer"
        className="block text-center p-1 text-sm underline"
      >
        åpne i trello
      </a>
      <iframe
        src="https://trello.com/b/NxvD64ZU.html"
        frameBorder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
}

export function Pim() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_PIM_API_URL,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them

    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (!token) return headers;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    credentials: "include",
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
    return <></>;
    // return <><button onClick={()=> loginWithRedirect()}>login</button></>
  }
  return (
    <ApolloProvider client={client}>
      <div className="h-full flex">
        <Sidebar
          mobileMenuOpen={mobileMenuOpen}
          user={user}
          setMobileMenuOpen={setMobileMenuOpen}
        />
        <div className="flex-1 flex flex-col h-full overflow-hidden">
          <Header setMobileMenuOpen={setMobileMenuOpen} user={user} />

          <main className="min-w-0 flex-1 h-full  flex flex-col lg:order-last overflow-y-scroll">
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route
                  index
                  element={
                    user?.org_id === process.env.REACT_APP_SPORTIENDA_ORG ? (
                      <Dashboard />
                    ) : (
                      <Navigate replace to="tradein" />
                    )
                  }
                />

                <Route path="trello">
                  <Route index element={<TrelloPage />} />
                </Route>

                <Route path="tradein">
                  <Route index element={<TradeInPage />} />
                  <Route path=":id" element={<TradeInWrapper />} />
                </Route>
                <Route path="outlet">
                  <Route index element={<OutletPage />} />
                  <Route path=":id" element={<OutletForm />} />
                </Route>

                <Route path="stats">
                  <Route path="purchase" element={<StatsPurchase />} />
                </Route>

                <Route path="pricequery">
                  <Route index element={<PriceQueryList type={null} />} />
                  <Route path=":id" element={<PriceQueryDetails />} />
                </Route>

                <Route path="protocol">
                  <Route index element={<Protocol />} />
                </Route>

                <Route path="intake">
                  <Route index element={<IntakeList />} />
                  <Route path=":id" element={<IntakeDetails />} />
                </Route>

                <Route path="cms/*" element={<CMS />} />
                <Route path="inventory">
                  <Route index element={<InventoryList />} />
                  <Route path=":id" element={<InventoryDetails />} />
                </Route>

                <Route path="value-by-year">
                  <Route index element={<ValueByYearList />} />
                </Route>
                {forms.map((schema) => (
                  <Route key={schema.id} path={schema.id}>
                    <Route index element={<ItemList schema={schema} />} />
                    <Route path=":id" element={<ItemEdit schema={schema} />} />
                  </Route>
                ))}
              </Routes>
            </Suspense>
          </main>
        </div>
      </div>
    </ApolloProvider>
  );
}

function PimAuth() {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(
      appState?.returnTo || appState?.targetUrl || window.location.pathname
    );
  };
  if (
    !process.env.REACT_APP_AUTH0_DOMAIN ||
    !process.env.REACT_APP_AUTH0_APP_IDENTIFIER_PIM ||
    !process.env.REACT_APP_API_PIM_AUDIENCE
  ) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-2xl font-semibold">
          Missing Auth0 env variables
        </div>
      </div>
    );
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_APP_IDENTIFIER_PIM}
      authorizationParams={{
        redirectUri: `${window.location.origin}`,
        scope:
          "admin read:current_user user_id email org_id update:current_user_metadata",
        audience: process.env.REACT_APP_API_PIM_AUDIENCE,
        onRedirectCallback: onRedirectCallback,
      }}
    >
      <Pim />
      <DevInfo />
    </Auth0Provider>
  );
}

export default function PimNavRoot() {
  return (
    <BrowserRouter>
      <PimAuth />
    </BrowserRouter>
  );
}
