import type { Option } from "@app/types";
export const stringToNumber = (str: string) => {
  const num = parseInt(str);
  if (isNaN(num)) {
    return null;
  }
  return num;
};

export function getTranslation(dictionary: any, key: string) {
  return dictionary[key] || key;
}

export const stringToBoolean = (str: string) => {
  return str === "on";
};

export const getOptionsFromEnum = (enumObject: any) =>
  Object.entries(enumObject)
    .filter(([key, _]) => Number.isNaN((key as any) - 0))
    .map(([key, value]) => ({
      id: value,
      name: key,
    })) as Option[];
