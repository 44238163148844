import React from "react";
import { classNames } from "../../utils/dom";

const inputClassNames = "block w-full border border-sportinputgrey rounded";
const checkClassNames = "block border border-sportinputgrey rounded";

const disabledClassNames = "opacity-60 cursor-not-allowed";

const createClassNames = (defaults, props, className) =>
  classNames(defaults, className, props.disabled && disabledClassNames);

export const Button = ({ className, children, ...props }) => (
  <button
    className={createClassNames(
      "rounded bg-spanish-green px-4 leading-9 text-white text-center hover:opacity-75",
      props,
      className
    )}
    {...props}
  >
    {children}
  </button>
);

export const TextField = (props) => (
  <input
    className={createClassNames(inputClassNames, props)}
    type="text"
    {...props}
  />
);

export const CheckField = (props) => (
  <input
    className={createClassNames(checkClassNames, props)}
    type="checkbox"
    {...props}
  />
);

export const SelectField = ({ options = [], ...props }) => (
  <select className={createClassNames(inputClassNames, props)} {...props}>
    {options.map(([k, v]) => (
      <option key={String(k)} value={k || ""}>
        {v}
      </option>
    ))}
  </select>
);
