import { format } from 'date-fns'
import { nb } from 'date-fns/locale'

export function dateFormat(date, scheme = 'dd.MM.yy') {
  return format(new Date(Date.parse(date)), scheme, { locale: nb })
}

export function dateTimeFormat(date, scheme = 'dd.MM.yy HH:ii:ss') {
  try{
   return format(new Date(Date.parse(date)), scheme, { locale: nb })
  }
  catch{
    return ""

  }
}
