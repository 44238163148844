import {
  StatsDashboard,
  // StatsFavourite,
  StatsInventory,
  useStatsDashboardQuery,
} from "@app/graphql/pim";

import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
// import { Link } from "react-router-dom";
import AuctionStats from "../components/atom/AuctionStats";
import Loading from "../components/atom/Loading";
import { classNames } from "../utils/dom";

type DashboardStatsProps = {
  data: StatsDashboard;
};

// type FavouritesProps = {
//   favourites: StatsFavourite[];
// };

// function Favourites({ favourites }: FavouritesProps) {
//   return (
//     <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
//       <div className="px-4 py-5 sm:px-6">Mest favorittert siste 2 måneder</div>
//       <div className="px-4 py-5 sm:p-6">
//         <ul role="list" className="divide-y divide-gray-100">
//           {favourites.map((favourite) => (
//             <li
//               key={favourite.id}
//               className="flex gap-x-4 py-5 justify-between"
//             >
//               <img
//                 className="h-12 w-18 flex-none rounded bg-gray-50"
//                 src={`https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/${favourite.image}/public`}
//                 alt=""
//               />
//               <div className="min-w-0 grow">
//                 <p className="text-sm font-semibold leading-6 text-gray-900">
//                   <Link
//                     to={`pim/inventory/${favourite.id}`}
//                     className="hover: underline"
//                   >
//                     {" "}
//                     {favourite.name}
//                   </Link>
//                 </p>
//               </div>
//               <div className="text-sm w-6 grow-0 font-semibold leading-6 text-gray-900">
//                 {favourite.count}
//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// }

function DashboardStats({ data }: DashboardStatsProps): JSX.Element {
  const stats = [
    {
      name: "Salg",
      stat: data.salesLastWeeks,
      previousStat: data.salesPrevWeeks,
      change: `${
        ((data.salesLastWeeks - data.salesPrevWeeks) / data.salesPrevWeeks) *
        100
      }%`,
      changeType:
        data.salesLastWeeks - data.salesPrevWeeks >= 0
          ? "increase"
          : "decrease",
    },
    {
      name: "Lagt ut til salgs",
      stat: data.onSalesLastWeeks,
      previousStat: data.onSalesPrevWeeks,
      change: `${
        ((data.onSalesLastWeeks - data.onSalesPrevWeeks) /
          data.onSalesPrevWeeks) *
        100
      }%`,
      changeType:
        data.onSalesLastWeeks - data.onSalesPrevWeeks >= 0
          ? "increase"
          : "decrease",
    },
    {
      name: "Signerte kjøp",
      stat: data.singaturesLastWeeks,
      previousStat: data.singaturesPrevWeeks,
      change: `${
        ((data.singaturesLastWeeks - data.singaturesPrevWeeks) /
          data.singaturesPrevWeeks) *
        100
      }%`,
      changeType:
        data.singaturesLastWeeks - data.singaturesPrevWeeks >= 0
          ? "increase"
          : "decrease",
    },
  ];

  return (
    <div>
      <h3 className="text-base font-semibold leading-6 text-gray-900">
        Siste 14 dager
      </h3>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">
                  from {item.previousStat}
                </span>
              </div>

              <div
                className={classNames(
                  item.changeType === "increase"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800",
                  "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                )}
              >
                {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only">
                  {" "}
                  {item.changeType === "increase"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by{" "}
                </span>
                {item.change}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

function InventoryStatsTable({
  groupedData,
  objectType,
}: {
  groupedData: any;
  objectType: string;
}): JSX.Element {
  if (!groupedData[objectType]) return <>No {objectType}</>;
  const subTypes = Object.keys(groupedData[objectType]);
  const statuses = Object.keys(groupedData[objectType][subTypes[0]] || {});

  return (
    <table
      key={objectType}
      className="table-auto border-collapse border border-gray-400 m-4 w-full"
    >
      <caption className="caption-top text-lg font-semibold mb-2">
        {objectType}
      </caption>
      <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2">Status \ SubType</th>
          {subTypes.map((subType) => (
            <th key={subType} className="border border-gray-300 px-4 py-2">
              {subType}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {statuses.map((status) => (
          <tr key={status}>
            <td className="border border-gray-300 px-4 py-2">{status}</td>
            {subTypes.map((subType) => (
              <td key={subType} className="border border-gray-300 px-4 py-2">
                {groupedData[objectType][subType][status] ? (
                  <>
                    <div>
                      Total: {groupedData[objectType][subType][status].total}
                    </div>
                    {Object.entries(
                      groupedData[objectType][subType][status].types
                    ).map(([type, count]) => (
                      <div key={type}>
                        {type}: <>{count || 0}</>
                      </div>
                    ))}
                  </>
                ) : (
                  <div>0</div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function InventoryStats({
  inventorystats,
}: {
  inventorystats: StatsInventory[];
}): JSX.Element {
  const groupedData = inventorystats.reduce((acc: any, item) => {
    const { objectType, subType, status, type, count } = item;
    if (!objectType || !subType || !status || !type || !count) return acc;
    const countInt = parseInt(count, 10);

    if (!acc[objectType]) acc[objectType] = {};
    if (!acc[objectType][subType]) acc[objectType][subType] = {};
    if (!acc[objectType][subType][status])
      acc[objectType][subType][status] = { total: 0, types: {} };
    if (!acc[objectType][subType][status].types[type])
      acc[objectType][subType][status].types[type] = 0;

    acc[objectType][subType][status].total += countInt;
    acc[objectType][subType][status].types[type] += countInt;

    return acc;
  }, {});
  return (
    <>
      <InventoryStatsTable groupedData={groupedData} objectType="BICYCLE" />
      <InventoryStatsTable groupedData={groupedData} objectType="SKI" />
    </>
  );
}

export default function Dashboard() {
  const { data, error, loading } = useStatsDashboardQuery();
  if (loading) return <Loading />;
  if (error) return <div>{error.message}</div>;
  const [keystats] = data?.statsDashboards?.nodes || [];
  const inventorystats = data?.statsInventories?.nodes || [];
  // const favourites = data?.statsFavourites?.nodes;

  return (
    <div className="p-4">
      {keystats && <DashboardStats data={keystats} />}
      <AuctionStats title="Avsluttede Auksjoner" open={false} />

      <AuctionStats />
      <InventoryStats inventorystats={inventorystats} />
      {/*
      <div className="grid lg:grid-cols-3 mt-4 ">
        {favourites && <Favourites favourites={favourites} />}
      </div> */}
    </div>
  );
}
