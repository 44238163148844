import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { classNames } from "../../utils/dom";

export default function SortableColumn({ children, field }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderBy = searchParams.get("orderBy") || "CREATED_DESC";
  const sortOrder = orderBy.endsWith("ASC") ? "ASC" : "DESC";

  const handleClick = (e) => {
    const { name } = e.target;
    let value = `${name}_${sortOrder}`;

    if (orderBy.startsWith(name)) {
      value = sortOrder === "ASC" ? `${name}_DESC` : `${name}_ASC`;
    }

    searchParams.set("orderBy", value);
    searchParams.delete("offset");
    setSearchParams(searchParams);
  };

  return (
    <div className="flex">
      <button
        type="button"
        name={field}
        className={classNames(
          orderBy.startsWith(field) ? "text-indigo-600" : "",
          "flex uppercase font-medium hover:text-indigo-500"
        )}
        onClick={handleClick}
      >
        {children || field}
        <div className="relative w-4 h-4 ml-1">
          <ChevronUpIcon
            className={classNames(
              orderBy.startsWith(field) && sortOrder === "ASC"
                ? "text-indigo-600"
                : "text-gray-300",
              "absolute -top-0.5 w-3.5 h-3.5"
            )}
          />
          <ChevronDownIcon
            className={classNames(
              orderBy.startsWith(field) && sortOrder === "DESC"
                ? "text-indigo-600"
                : "text-gray-300",
              "absolute -bottom-0.5 w-3.5 h-3.5"
            )}
          />
        </div>
      </button>
    </div>
  );
}
