import { SearchRecord, usePimSearchQuery } from "@app/graphql/pim";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  QuestionMarkCircleIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../utils/dom";

import {
  DocumentPlusIcon,
  FolderIcon,
  FolderPlusIcon,
  HashtagIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";

type Project = {
  id: number;
  name: string;
  url: string;
};

const projects = [
  { id: 1, name: "Workflow Inc. / Website Redesign", url: "#" },
  // More projects...
] as Project[];
const recent = [projects[0]];
const quickActions = [
  { name: "Add new file...", icon: DocumentPlusIcon, shortcut: "N", url: "#" },
  { name: "Add new folder...", icon: FolderPlusIcon, shortcut: "F", url: "#" },
  { name: "Add hashtag...", icon: HashtagIcon, shortcut: "H", url: "#" },
  { name: "Add label...", icon: TagIcon, shortcut: "L", url: "#" },
];

const YELLOW = "bg-yellow-50 text-yellow-800 ring-yellow-600/20";
const GREEN = "bg-green-50 text-green-700 ring-green-600/20";
const RED = "bg-red-50 text-red-700 t ring-red-600/10";
const GRAY = "bg-gray-50 text-gray-600 ring-gray-500/10";

const STATUSCOLORS = {
  accepted: YELLOW,
  for_sale: GREEN,
  draft: RED,
  auction_done: GREEN,
  signed: GREEN,
  signature_requested: YELLOW,
  auction: GREEN,
  handed_in: GREEN,
  submitted: RED,
  for_sale_hidden: GREEN,
  sold: RED,
} as { [key: string]: string };

type TypeMap = {
  [key: string]: {
    url: string;
    icon: any;
  };
};

const TYPEMAP = {
  bicycle_price_query: {
    url: "/pricequery/",
    icon: QuestionMarkCircleIcon,
  },
  inventory: { url: "/inventory/", icon: ShoppingCartIcon },
} as TypeMap;

export default function CommandPallet({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [query, setQuery] = useState("");

  const { data, loading } = usePimSearchQuery({
    variables: {
      q: query,
    },
    skip: query === "" || query.length < 3,
    context: { clientName: "public" },
  });

  const navigate = useNavigate();

  const searchResult =
    data?.search?.nodes?.map((node) => ({ ...node })) || ([] as SearchRecord[]);

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-80 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all">
              <Combobox
                onChange={(item: SearchRecord) => {
                  navigate(`${item.type && TYPEMAP[item.type]?.url}${item.id}`);
                  setOpen(false);
                }}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-900 text-opacity-40"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {searchResult.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-80 scroll-py-2 divide-y divide-gray-500 divide-opacity-10 overflow-y-auto"
                  >
                    <li className="p-2">
                      {/* {query === "" && (
                        <h2 className="mb-2 mt-4 px-3 text-xs font-semibold text-gray-900">
                          Recent searches
                        </h2>
                      )} */}
                      <ul className="text-sm text-gray-700">
                        {searchResult.map((node) => {
                          if (
                            !node ||
                            !node.id ||
                            !node.title ||
                            !node.type ||
                            !TYPEMAP[node.type]
                          )
                            return null;
                          const NodeIcon = TYPEMAP[node.type].icon;

                          return (
                            <Combobox.Option
                              key={node.id}
                              value={node}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  active &&
                                    "bg-gray-900 bg-opacity-5 text-gray-900"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <NodeIcon
                                    className={classNames(
                                      "h-6 w-6 flex-none text-gray-900 text-opacity-40",
                                      active && "text-opacity-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <div className="ml-3 flex-auto">
                                    <span className="truncate grid grid-flow-row">
                                      <span>
                                        <span>{node.title}</span>

                                        <span
                                          className={`ml-3 inline-flex items-center rounded-full  px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                                            node.status &&
                                            STATUSCOLORS.hasOwnProperty(
                                              node.status
                                            )
                                              ? STATUSCOLORS[node.status]
                                              : GRAY
                                          }`}
                                        >
                                          {node.status}
                                        </span>
                                      </span>

                                      <span className="truncate text-gray-400 font-xs">
                                        <span className="">
                                          {node.description}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  {active && (
                                    <span className="ml-3 flex-none text-gray-500">
                                      Jump to...
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          );
                        })}
                      </ul>
                    </li>
                    {/* {query === "" && (
                      <li className="p-2">
                        <h2 className="sr-only">Quick actions</h2>
                        <ul className="text-sm text-gray-700">
                          {quickActions.map((action) => (
                            <Combobox.Option
                              key={action.shortcut}
                              value={action}
                              className={({ active }) =>
                                classNames(
                                  "flex cursor-default select-none items-center rounded-md px-3 py-2",
                                  active &&
                                    "bg-gray-900 bg-opacity-5 text-gray-900"
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <action.icon
                                    className={classNames(
                                      "h-6 w-6 flex-none text-gray-900 text-opacity-40",
                                      active && "text-opacity-100"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {action.name}
                                  </span>
                                  <span className="ml-3 flex-none text-xs font-semibold text-gray-500">
                                    <kbd className="font-sans">⌘</kbd>
                                    <kbd className="font-sans">
                                      {action.shortcut}
                                    </kbd>
                                  </span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )} */}
                  </Combobox.Options>
                )}

                {query !== "" &&
                  query.length >= 3 &&
                  searchResult.length === 0 && (
                    <div className="px-6 py-14 text-center sm:px-14">
                      <FolderIcon
                        className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                        aria-hidden="true"
                      />
                      <p className="mt-4 text-sm text-gray-900">
                        We couldn't find any items with that term. Please try
                        again.
                      </p>
                    </div>
                  )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
