export function trackEvent(event: string, properties?: any) {
  if (process.env.NODE_ENV === "development") {
    console.log("Tracked event:", event, properties);
  }
  //ecent lower case and replace space with underscore
  // const gaEvent = event.toLowerCase().replace(/ /g, "_");
  // if (window.gtag) {
  //   window.gtag("event", gaEvent, properties);
  // }
}
