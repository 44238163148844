import {
  useCreateDiscountCodeMutation,
  useDiscountCodeQuery,
  useDiscountCodesQuery,
  useUpdateDiscountCodeMutation,
} from "@app/graphql/pim";
import { FieldType, Form, ItemSchema, ListColumn } from "@app/types";
import { z } from "zod";
import { stringToNumber } from "../utils/schema";

export const addInitialValues = {
  discountCode: null,
  bicycleDiscount: 0,
  skiDiscount: 0,
  published: false,
};

export const addForm: Form = [
  {
    id: "discountCode",
    name: "DiscountCode",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },

  {
    id: "bicycleDiscount",
    name: "Bicycle Discount",
    type: FieldType.number,
    parseFormData: stringToNumber,
    required: true,
    zFieldType: z.number(),
  },
  {
    id: "skiDiscount",
    name: "Ski Discount",
    type: FieldType.number,
    parseFormData: stringToNumber,
    required: true,
    zFieldType: z.number(),
  },
  {
    id: "published",
    name: "Published",
    type: FieldType.boolean,
    required: true,
    zFieldType: z.boolean().optional(),
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "discountCode",
    name: "Discount Code",
  },

  {
    id: "bicycleDiscount",
    name: "Bicycle Discount",
  },
  {
    id: "skiDiscount",
    name: "Ski Discount",
  },
  {
    id: "published",
    name: "Published",
    parseFormData: (data) => (data ? "Yes" : "No"),
  },
  {
    id: "created",
    name: "Opprettet",
  },
  {
    id: "lastUpdated",
    name: "Oppdatert",
  },
];

const discountCode: ItemSchema = {
  id: "discountCode",
  name: "Rabattkode",
  plural: "Rabattkoder",
  listColumns,
  addForm,
  editForm,
  addInitialValues,
  editInitialValues,
  useItemsQuery: useDiscountCodesQuery,
  useItemQuery: useDiscountCodeQuery,
  useCreateItemMutation: useCreateDiscountCodeMutation,
  useUpdateItemMutation: useUpdateDiscountCodeMutation,
  parseItem: (data) => data?.discountCode || {},
  parseItems: (data) =>
    ({
      totalCount: data?.discountCodes?.totalCount,
      items: data?.discountCodes?.nodes,
    } || []),
  createInputFromValues: (values) => ({
    discountCode: values,
  }),
};

export default discountCode;
