// CREATE TABLE if not exists app_hidden.org_permission (
//     org_id TEXT NOT NULL REFERENCES app.org(id),
//     permission app_hidden.org_permission_type NOT NULL,
//     inventory_type app.inventory_type,  -- Nullable if the permission isn't inventory-specific
//     object_type app.object_type,        -- Nullable if the permission isn't object-specific
//     PRIMARY KEY (org_id, permission, inventory_type, object_type)
// );
import {
  OrgPermissionType,
  InventoryType,
  ObjectType,
  useOrgPermissionsQuery,
  useOrgPermissionQuery,
  useCreateOrgPermissionMutation,
  useUpdateOrgPermissionMutation,
} from "@app/graphql/pim";
import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";
import { getOptionsFromEnum } from "../utils/schema";

export const addInitialValues = {
  orgId: null,
  permission: null,
  inventoryType: null,
  objectType: null,
};

export const addForm: Form = [
  {
    id: "orgId",
    name: "Organization ID",
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "permission",
    name: "Permission",
    type: FieldType.select,
    values: getOptionsFromEnum(OrgPermissionType),
    zFieldType: z.nativeEnum(OrgPermissionType),
    required: true,
  },
  {
    id: "inventoryType",
    name: "Inventory Type",
    type: FieldType.select,
    required: false,
    values: getOptionsFromEnum(InventoryType),
    zFieldType: z.nativeEnum(InventoryType).nullable(),
  },
  {
    id: "objectType",
    name: "Object Type",
    type: FieldType.select,
    required: false,
    values: getOptionsFromEnum(ObjectType),
    zFieldType: z.nativeEnum(ObjectType).nullable(),
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "orgId",
    name: "Organization ID",
  },
  {
    id: "permission",
    name: "Permission",
  },
  {
    id: "inventoryType",
    name: "Inventory Type",
  },
  {
    id: "objectType",
    name: "Object Type",
  },
];

const filters: ItemFilter[] = [
  {
    name: "orgId",
    type: "text",
    subtype: "like",
  },
  {
    name: "permission",
    type: "enum",
    enum: OrgPermissionType,
  },
];

const orgPermission: ItemSchema = {
  id: "orgPermission",
  name: "Organization Permission",
  plural: "Organization Permissions",
  idIsNumber: false,
  listColumns,
  addForm,
  filters,
  editForm,
  addInitialValues,
  editInitialValues,
  useItemsQuery: useOrgPermissionsQuery,
  useItemQuery: useOrgPermissionQuery,
  useCreateItemMutation: useCreateOrgPermissionMutation,
  useUpdateItemMutation: useUpdateOrgPermissionMutation,
  parseItem: (data) => data?.orgPermission || {},
  parseItems: (data) =>
    ({
      totalCount: data?.orgPermissions?.totalCount,
      items: data?.orgPermissions?.nodes,
    } || []),
  createInputFromValues: (values) => ({
    orgPermission: values,
  }),
};

export default orgPermission;
