import { Suspense } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import Loading from "../../components/atom/Loading";
import { classNames } from "../../utils/dom";
import { RecursiveRoute } from "./Item";

import schemas from "../../schemata";

function SubNav() {
  return (
    <nav className="flex-none px-4 sm:px-6 lg:px-0">
      <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col ml-4">
        {schemas.map((item) => (
          <li key={item.id}>
            <NavLink
              to={item.id}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-gray-200 text-sportgreen-600"
                    : "text-gray-700 hover:text-sportgreen-600 hover:bg-gray-200",
                  "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                )
              }
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default function CMS() {
  return (
    <>
      <main className="">
        <div className="xl:pl-96">
          <div className="px-4 py-10  lg:py-6">
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<SubNav />} />
                {schemas.map((schema) => (
                  <Route
                    key={schema.id}
                    path={`${schema.id}/*`}
                    element={<RecursiveRoute schema={schema} path={""} />}
                  />
                ))}
              </Routes>
            </Suspense>
          </div>
        </div>
      </main>

      <aside className="fixed inset-y-0 left-20 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 py-24 sm:px-6 lg:px-8 xl:block">
        <SubNav />
      </aside>
    </>
  );
}
