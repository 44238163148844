import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  CheckCircleIcon,
  NoSymbolIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import { useParams } from "react-router-dom";
import InventoryHeader from "../../components/atom/InventoryHeader";
import Loading from "../../components/atom/Loading";
import CheckStolenBike from "../../components/atom/StolenBike";
import ZebraDetailList from "../../components/atom/ZebraDetailList";
import {
  BooleanField,
  FormFields,
  findCond,
  initialFormIntake,
} from "../../components/common/Form";
import Steps from "../../components/common/Steps";
import BikeNotFound from "../../components/layout/BikeNotFound";
import {
  DeleteteButton,
  SaveButton,
  StartButton,
  SubmitButton,
} from "./IntakeForm/Buttons";

import { dateTimeFormat } from "../../utils/dates";

import {
  conditionsFromData,
  extraIntakeFieldsFromData,
  intakeSteps,
  typesFromData,
} from "@app/config";

/* eslint-disable react/prop-types */

const INTAKE_VALUE_CALC_QUERY = gql`
  query BicycleIntakeFormQuery($id: UUID!) {
    bicycleIntakeForm(id: $id) {
      id
      calculatedSportiendaScore
      scoreDiscount
      discountDampersFront
      discountDampersBack
      discountBattery
      discountMotor
      currentPrice
      calculatedOfferPrice
      conditionTiresFront
      conditionTiresBack
      conditionBrakes
      conditionWheelsFront
      conditionWheelsBack
      typeBonus
      electricBonus
      electric
      bicycleType
      driveType
      km
      comment
      batteryIncluded
      keysIncluded
      keyBrand
      keyNumber
      chargerIncluded
      brandMotor
      breakType
      batteryStatusV
      batteryStatusAh
      batteryStatusWh
      bicyclePriceQuery {
        id
        clubCode
        retailPriceNok
        depreciatedValue
        brandBonus
        calculatedSportiendaScore
        scoreDiscount
        discountDampersFront
        discountDampersBack
        discountBattery
        discountMotor
        currentPrice
        calculatedOfferPrice
        conditionTiresFront
        conditionTiresBack
        conditionBrakes
        conditionWheelsFront
        conditionWheelsBack
        electric
        brandMotor
        breakType
        typeBonus
        electricBonus
        bicycleType
        driveType
        km
        bicycle {
          id
          year
          model {
            id
            brandFamily {
              id
              brand {
                id
                name
              }
            }
          }
          price
          currency
        }
      }
    }
  }
`;
//

//

//
//

const INTAKE_QUERY = gql`
  query BicycleIntakeFormQuery($id: UUID!) {
    bicycleIntakeForm(id: $id) {
      id
      step
      status
      comment
      submittedByUserId
      scoreDiscount
      serviceOrgId
      estimatedServiceCost
      conditionGeneral
      conditionCosmeticFrame
      conditionHeadset
      conditionCrank
      conditionBottomBracket
      conditionCasette
      conditionChain
      conditionDampersFront
      conditionDampersBack
      conditionBattery
      conditionMotor
      conditionTiresFront
      conditionTiresBack
      conditionBrakes
      conditionWheelsFront
      conditionWheelsBack
      typeBonus
      electricBonus
      electric
      brandMotor
      breakType
      bicycleType
      driveType
      km
      shippingReference
      estimatedArrival
      frameNumber
      batteryIncluded
      keysIncluded
      keyBrand
      keyNumber
      chargerIncluded
      batteryStatusV
      batteryStatusAh
      batteryStatusWh
      sku
      inventories(first: 1) {
        nodes {
          id
          order {
            id
          }

          status
        }
      }
      bicyclePriceQuery {
        id
        ownerFirstName
        ownerLastName
        currentPrice
        scoreDiscount
        calculatedSportiendaScore
        frameNumber
        conditionGeneral
        conditionCosmeticFrame
        conditionHeadset
        conditionCrank
        conditionBottomBracket
        conditionCasette
        conditionChain
        conditionTires
        conditionWheels
        conditionDampersFront
        conditionDampersBack
        conditionBattery
        conditionMotor
        conditionTiresFront
        conditionTiresBack
        conditionBrakes
        conditionWheelsFront
        conditionWheelsBack
        electric
        brandMotor
        breakType
        typeBonus
        electricBonus
        bicycleType
        driveType
        km
        comment
        purchaseProtocol {
          sequence
        }
        bicycle {
          id
          name
          year
        }
        org {
          id
          name
        }
      }
    }
  }
`;

function IntakeValueCalculations() {
  const { user } = useAuth0();
  const params = useParams();
  const { loading, data } = useQuery(INTAKE_VALUE_CALC_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  });
  console.log(process.env.REACT_APP_SPORTIENDA_ORG);
  console.log(user?.org_id);
  console.log(user?.org_id === process.env.REACT_APP_SPORTIENDA_ORG);
  console.log(data);

  if (user?.org_id !== process.env.REACT_APP_SPORTIENDA_ORG) {
    return null;
  }
  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <></>;
  }

  const bicycleIntakeForm = data?.bicycleIntakeForm;
  const priceQuery = bicycleIntakeForm?.bicyclePriceQuery;

  const valueCalculations = [
    {
      title: `Nypris (${priceQuery?.bicycle?.year})`,
      user: `${priceQuery?.bicycle?.price} ${priceQuery?.bicycle?.currency}`,
      expert: `${priceQuery?.bicycle?.price} ${priceQuery?.bicycle?.currency}`,
    },
    {
      title: `Nypris NOK (${priceQuery?.bicycle?.year})`,
      user: `${priceQuery?.retailPriceNok} NOK`,
      expert: `${priceQuery?.retailPriceNok} NOK`,
    },
    {
      title: `Nåpris`,
      user: `${priceQuery?.depreciatedValue} NOK`,
      expert: `${priceQuery?.depreciatedValue} NOK`,
    },
    {
      title: `Brand bonus faktor (${priceQuery?.bicycle?.model?.brandFamily?.brand?.name})`,
      user: priceQuery?.brandBonus,
      expert: priceQuery?.brandBonus,
    },
    {
      title: `SportiendaScore`,
      user: priceQuery?.calculatedSportiendaScore,
      expert: bicycleIntakeForm?.calculatedSportiendaScore,
    },
    {
      title: `Score fratrekk faktor`,
      user: priceQuery?.scoreDiscount,
      expert: bicycleIntakeForm?.scoreDiscount,
    },
    {
      title: `Electrisk bonus faktor`,
      user: priceQuery?.electricBonus,
      expert: bicycleIntakeForm?.electricBonus,
    },
    {
      title: `Type bonus faktor`,
      user: priceQuery?.typeBonus,
      expert: bicycleIntakeForm?.typeBonus,
    },
    {
      title: `Fratrekk depere forran`,
      user: priceQuery?.discountDampersFront,
      expert: bicycleIntakeForm?.discountDampersFront,
    },
    {
      title: `Fratrekk dempere bak`,
      user: priceQuery?.discountDampersBack,
      expert: bicycleIntakeForm?.discountDampersBack,
    },
    {
      title: `Fratrekk batteri`,
      user: priceQuery?.discountBattery,
      expert: bicycleIntakeForm?.discountBattery,
    },
    {
      title: `Fratrekk motor`,
      user: priceQuery?.discountMotor,
      expert: bicycleIntakeForm?.discountMotor,
    },
    {
      title: `Nåverdi`,
      user: priceQuery?.currentPrice,
      expert: bicycleIntakeForm?.currentPrice,
    },
    {
      title: `Tilbud til kunde`,
      user: priceQuery?.calculatedOfferPrice,
      expert: bicycleIntakeForm?.calculatedOfferPrice,
    },
  ];

  return (
    <div className="mt-8 flex flex-col">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Valucalculations
        </h3>
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Expert
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    User
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {valueCalculations.map((value, idx) => (
                  <tr key={idx}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {value.title}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {value.expert}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {value.user}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function IntakeDetails() {
  const params = useParams();

  const { loading, refetch, data } = useQuery(INTAKE_QUERY, {
    variables: { id: params.id },
    pollInterval: 10000,
  });

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <BikeNotFound id={params.id} />;
  }

  const { bicycleIntakeForm } = data;

  const structuredData = {
    step: 1,
    status: null,
    ...initialFormIntake,
  };

  Object.entries(structuredData).forEach(([key]) => {
    structuredData[key] = bicycleIntakeForm[key];
  });

  return (
    <IntakeDetailsWithData
      structuredData={structuredData}
      bicycleIntakeForm={bicycleIntakeForm}
      id={params.id}
      callback={refetch}
    />
  );
}

function IntakeDetailsWithData({
  structuredData,
  bicycleIntakeForm,
  id,
  callback,
}) {
  const [data, setData] = useState(structuredData);
  const [showDelete, setShowDelete] = useState(false);
  const onUpdate = (name) => (value) =>
    setData({
      ...data,
      [name]: value,
    });

  const createFieldProps = (fieldId, fieldName) => ({
    fieldId,
    fieldName,
    value: data[fieldId],
    onChange: onUpdate(fieldId),
  });

  const sels = [
    ["frameNumber", "Rammenummer", String, true],
    ...typesFromData(data),
    ...conditionsFromData(data),
  ];

  return (
    <>
      <InventoryHeader
        name={bicycleIntakeForm?.bicyclePriceQuery?.bicycle?.name}
        bicyclePriceQuery={bicycleIntakeForm?.bicyclePriceQuery}
        bicycleIntakeForm={bicycleIntakeForm}
        inventory={bicycleIntakeForm?.inventories?.nodes[0]}
        order={bicycleIntakeForm?.inventories?.nodes[0]?.order}
      />

      <Steps steps={intakeSteps} current={data.step} />

      <div className="step mx-2 mt-4 flex flex-col space-y-8">
        {bicycleIntakeForm.status === "IN_TRANSIT" && (
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <div className="flex flex-col items-center">
                <StartButton id={id} callback={callback} />
              </div>
            </div>
          </div>
        )}
        {bicycleIntakeForm.status === "STARTED" &&
          bicycleIntakeForm.step === 0 && (
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <FormFields
                  schemaFunction={typesFromData}
                  data={data}
                  updateField={onUpdate}
                />

                <FormFields
                  schemaFunction={conditionsFromData}
                  data={data}
                  updateField={onUpdate}
                />

                <FormFields
                  schemaFunction={extraIntakeFieldsFromData}
                  data={data}
                  updateField={onUpdate}
                />
              </div>

              <div className="mt-4 mb-4 flex  justify-start">
                <SaveButton
                  data={data}
                  id={id}
                  steps={intakeSteps}
                  callback={callback}
                />
              </div>
            </div>
          )}

        {(((bicycleIntakeForm.status === "STARTED" ||
          bicycleIntakeForm.status === "IN_TRANSIT") &&
          bicycleIntakeForm.step === 1) ||
          bicycleIntakeForm.status === "DONE") && (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className=" mb-8 flex flex-col">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Tilstand
                </h3>
              </div>
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Title
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Expert
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            User
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Equal
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {sels.map(([name, label, cond], key) => (
                          <tr key={key}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {label}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {[Number, Boolean, String].indexOf(cond) !== -1
                                ? String(data[name])
                                : findCond([name, label, cond], data)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {[Number, Boolean, String].indexOf(cond) !== -1
                                ? String(
                                    bicycleIntakeForm?.bicyclePriceQuery[name]
                                  )
                                : findCond(
                                    [name, label, cond],
                                    bicycleIntakeForm?.bicyclePriceQuery
                                  )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {data[name] ===
                              bicycleIntakeForm?.bicyclePriceQuery[name] ? (
                                <CheckCircleIcon
                                  className="h-5 w-5 text-sportgreen"
                                  aria-hidden="true"
                                />
                              ) : (
                                <NoSymbolIcon
                                  className="h-5 w-5 text-sportred"
                                  aria-hidden="true"
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <ZebraDetailList
              rows={[
                ["Kommentar", bicycleIntakeForm?.comment],
                [
                  "Priseforespørsel kommentar",
                  bicycleIntakeForm?.bicyclePriceQuery?.comment,
                ],
                [
                  "Batteri inkludert",
                  bicycleIntakeForm?.batteryIncluded ? (
                    <CheckCircleIcon
                      className="h-5 w-5 text-sportgreen"
                      aria-hidden="true"
                    />
                  ) : (
                    <XCircleIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ),
                ],

                [
                  "Nøkler inkludert",
                  bicycleIntakeForm?.keysIncluded ? (
                    <CheckCircleIcon
                      className="h-5 w-5 text-sportgreen"
                      aria-hidden="true"
                    />
                  ) : (
                    <XCircleIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ),
                ],
                ["Nøkkelmerke", bicycleIntakeForm?.keyBrand],
                ["Nøkkelnummer", bicycleIntakeForm?.keyNumber],
                [
                  "Lader inkludert",
                  bicycleIntakeForm?.chargerIncluded ? (
                    <CheckCircleIcon
                      className="h-5 w-5 text-sportgreen"
                      aria-hidden="true"
                    />
                  ) : (
                    <XCircleIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ),
                ],
                [
                  "Batteristatus",
                  `V: ${bicycleIntakeForm?.batteryStatusV}%, Ah: ${bicycleIntakeForm?.batteryStatusAh}%, Wh: ${bicycleIntakeForm?.batteryStatusWh}%`,
                ],

                [
                  "Beregnet servicekostnad",
                  bicycleIntakeForm?.estimatedServiceCost,
                ],

                ["Org", bicycleIntakeForm?.bicyclePriceQuery?.org?.name],
              ]}
            />

            <IntakeValueCalculations intake_form_id={bicycleIntakeForm.id} />
            <div className="mt-8 flex flex-col">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Shipping details
                </h3>
              </div>
              <ZebraDetailList
                rows={[
                  ["Shipping reference", bicycleIntakeForm?.shippingReference],
                  [
                    "Estimated arrival",
                    dateTimeFormat(bicycleIntakeForm?.estimatedArrival),
                  ],
                ]}
              />

              {/* {bicycleIntakeForm?.status === "IN_TRANSIT" && (
                <div className="my-4 text-right">
                  <StartButton id={id} className="inline-flex" />
                </div>
              )} */}
            </div>
            <CheckStolenBike
              bicyclePriceQueryId={bicycleIntakeForm?.bicyclePriceQuery?.id}
            />
            <div className="mt-4 flex  justify-start">
              {(bicycleIntakeForm.status === "STARTED" ||
                bicycleIntakeForm.status === "IN_TRANSIT") && (
                <SaveButton
                  data={data}
                  id={id}
                  steps={intakeSteps}
                  callback={callback}
                />
              )}
              {(bicycleIntakeForm.status === "STARTED" ||
                bicycleIntakeForm.status === "IN_TRANSIT") && (
                <SubmitButton callback={callback} id={id} />
              )}
            </div>
            <div className="mt-4 flex  justify-start">
              <div className="w-40">
                <BooleanField
                  name="Vis sletteknapp"
                  value={showDelete}
                  onChange={setShowDelete}
                />
              </div>
              {showDelete && <DeleteteButton id={id} />}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
